/**
 * @generated SignedSource<<e66864db6ae3450df14b88ebd54b01e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OperationType = "MUTATION" | "QUERY" | "SUBSCRIPTION";
export type RequestFilters = {
  branchName?: string | null | undefined;
  clientName?: ReadonlyArray<string> | null | undefined;
  clientVersion?: ReadonlyArray<string> | null | undefined;
  duration?: DurationFilter | null | undefined;
  errorCode?: ReadonlyArray<string> | null | undefined;
  from?: string | null | undefined;
  httpStatusCode?: ReadonlyArray<number> | null | undefined;
  operationName?: ReadonlyArray<string> | null | undefined;
  operationType?: ReadonlyArray<OperationType> | null | undefined;
  range?: any | null | undefined;
  to: string;
  traceId?: ReadonlyArray<string> | null | undefined;
};
export type DurationFilter = {
  gt?: any | null | undefined;
  gte?: any | null | undefined;
  lt?: any | null | undefined;
  lte?: any | null | undefined;
};
export type tracesPageQuery$variables = {
  account: string;
  after?: string | null | undefined;
  before?: string | null | undefined;
  filters: RequestFilters;
  first?: number | null | undefined;
  graph: string;
  last?: number | null | undefined;
};
export type tracesPageQuery$data = {
  readonly graph: {
    readonly " $fragmentSpreads": FragmentRefs<"tracesTableFragment">;
  } | null | undefined;
};
export type tracesPageQuery = {
  response: tracesPageQuery$data;
  variables: tracesPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "account"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "graph"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v7 = [
  {
    "kind": "Variable",
    "name": "accountSlug",
    "variableName": "account"
  },
  {
    "kind": "Variable",
    "name": "graphSlug",
    "variableName": "graph"
  }
],
v8 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "before"
  },
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "tracesPageQuery",
    "selections": [
      {
        "alias": "graph",
        "args": (v7/*: any*/),
        "concreteType": "Graph",
        "kind": "LinkedField",
        "name": "graphByAccountSlug",
        "plural": false,
        "selections": [
          {
            "args": (v8/*: any*/),
            "kind": "FragmentSpread",
            "name": "tracesTableFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v5/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v6/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "tracesPageQuery",
    "selections": [
      {
        "alias": "graph",
        "args": (v7/*: any*/),
        "concreteType": "Graph",
        "kind": "LinkedField",
        "name": "graphByAccountSlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "RequestConnection",
            "kind": "LinkedField",
            "name": "requests",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RequestEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Request",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Trace",
                        "kind": "LinkedField",
                        "name": "trace",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RequestOperation",
                        "kind": "LinkedField",
                        "name": "operations",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "type",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "httpStatusCode",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "errorCount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "clientName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "clientVersion",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "rootSpanId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasPreviousPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startCursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v8/*: any*/),
            "filters": [
              "filters"
            ],
            "handle": "connection",
            "key": "TracesPage_branch_requests",
            "kind": "LinkedHandle",
            "name": "requests"
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d8c9cae96e696c810260789509008f4d",
    "id": null,
    "metadata": {},
    "name": "tracesPageQuery",
    "operationKind": "query",
    "text": "query tracesPageQuery(\n  $account: String!\n  $graph: String!\n  $filters: RequestFilters!\n  $first: Int\n  $after: String\n  $last: Int\n  $before: String\n) {\n  graph: graphByAccountSlug(accountSlug: $account, graphSlug: $graph) {\n    ...tracesTableFragment_340q7A\n    id\n  }\n}\n\nfragment traceRowFragment on Request {\n  id\n  trace {\n    id\n  }\n  startedAt\n  endedAt\n  operations {\n    name\n    type\n  }\n  httpStatusCode\n  errorCount\n  clientName\n  clientVersion\n  rootSpanId\n}\n\nfragment tracesTableFragment_340q7A on Graph {\n  requests(filters: $filters, after: $after, first: $first, last: $last, before: $before) {\n    edges {\n      node {\n        ...traceRowFragment\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n      hasPreviousPage\n      startCursor\n    }\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "817bad0bfd25cbe1790bfb4c33d6846f";

export default node;
