import { type CSSProperties, type JSX, forwardRef } from 'react'

import { vars } from '#lib/theme/theme.css'
import clsx from 'clsx'

import { spinnerStyle } from './spinner.css'

type Props = {
  size?: CSSProperties['width']
} & JSX.IntrinsicElements['div']

export const Spinner = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { size = 48 } = props

  return (
    <div
      {...props}
      ref={ref}
      className={clsx(spinnerStyle, props.className)}
      style={
        {
          '--spinner-size': typeof size === 'number' ? `${size}px` : size,
          ...props.style,
        } as CSSProperties
      }
    />
  )
})

Spinner.displayName = 'Spinner'

export function FullpageSpinner() {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        flex: 1,
        color: vars.color.neutral11,
        paddingBlock: '4rem',
      }}
    >
      <Spinner />
    </div>
  )
}
