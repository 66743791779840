/**
 * @generated SignedSource<<0d6d13fe551350b4ddf9c0e2c85d962c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type deploymentDetailsPageQuery$variables = {
  id: string;
};
export type deploymentDetailsPageQuery$data = {
  readonly deployment: {
    readonly createdAt: string;
    readonly " $fragmentSpreads": FragmentRefs<"commitDetailsDeploymentFragment" | "deploymentDetailsPageStatusFragment">;
  } | null | undefined;
};
export type deploymentDetailsPageQuery = {
  response: deploymentDetailsPageQuery$data;
  variables: deploymentDetailsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deploymentDetailsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Deployment",
        "kind": "LinkedField",
        "name": "deployment",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "deploymentDetailsPageStatusFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "commitDetailsDeploymentFragment"
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deploymentDetailsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Deployment",
        "kind": "LinkedField",
        "name": "deployment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "duration",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "triggerType",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e66a00c2af0e95bf7deeeed3077b84ed",
    "id": null,
    "metadata": {},
    "name": "deploymentDetailsPageQuery",
    "operationKind": "query",
    "text": "query deploymentDetailsPageQuery(\n  $id: ID!\n) {\n  deployment(id: $id) {\n    ...deploymentDetailsPageStatusFragment\n    ...commitDetailsDeploymentFragment\n    createdAt\n    id\n  }\n}\n\nfragment commitDetailsDeploymentFragment on Deployment {\n  triggerType\n}\n\nfragment deploymentDetailsPageStatusFragment on Deployment {\n  status\n  duration\n  id\n}\n"
  }
};
})();

(node as any).hash = "62ae36c1efbf5e59c3df08eb581cdf99";

export default node;
