import type { LimitCycleChoice } from '#gen/relay/accountUsagePageQuery.graphql'
import { endOfDay, formatRFC3339, startOfDay, subDays } from 'date-fns'
import {
  type InferOutput,
  length,
  literal,
  object,
  optional,
  picklist,
  pipe,
  string,
  union,
} from 'valibot'

export const UsagePageSearchParamsSchema = union([
  object({
    graph: optional(string()),
    period: optional(picklist(['CURRENT', 'PREVIOUS', '7D', '14D', '30D'])),
  }),
  object({
    graph: optional(string()),
    period: literal('CUSTOM'),
    startDate: pipe(string(), length(10)),
    endDate: pipe(string(), length(10)),
  }),
])

export type UsagePageSearchParams = InferOutput<
  typeof UsagePageSearchParamsSchema
>

export function createUsagePageQueryVariables(search: UsagePageSearchParams) {
  let cycle: LimitCycleChoice | null = 'CURRENT'
  let startDate: string | null = null
  let endDate: string | null = null

  if (search.period === 'CUSTOM') {
    cycle = null
    startDate = formatRFC3339(startOfDay(search.startDate))
    endDate = formatRFC3339(endOfDay(search.endDate))
  } else if (search.period === 'PREVIOUS') {
    cycle = 'PREVIOUS'
  } else if (search.period) {
    cycle = null
    let days = 7

    switch (search.period) {
      case '7D':
        days = 7
        break
      case '14D':
        days = 14
        break
      case '30D':
        days = 30
        break
    }

    const today = new Date()
    startDate = formatRFC3339(startOfDay(subDays(today, days)))
    endDate = formatRFC3339(endOfDay(today))
  }

  return {
    cycle,
    startDate,
    endDate,
    graphIds: search.graph ? [search.graph] : null,
  }
}
