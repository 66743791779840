/**
 * @generated SignedSource<<a52c5e2467c6bbd9d2dd359b4678a77d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type checksConfigurationPageQuery$variables = {
  account: string;
  graph: string;
};
export type checksConfigurationPageQuery$data = {
  readonly graphByAccountSlug: {
    readonly " $fragmentSpreads": FragmentRefs<"branchesFragment" | "excludedClientsFragment" | "excludedOperationsFragment" | "timeRangeFragment">;
  } | null | undefined;
};
export type checksConfigurationPageQuery = {
  response: checksConfigurationPageQuery$data;
  variables: checksConfigurationPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "account"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "graph"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "accountSlug",
    "variableName": "account"
  },
  {
    "kind": "Variable",
    "name": "graphSlug",
    "variableName": "graph"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "operationChecksEnabled",
    "storageKey": null
  },
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "checksConfigurationPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Graph",
        "kind": "LinkedField",
        "name": "graphByAccountSlug",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "excludedOperationsFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "excludedClientsFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "timeRangeFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "branchesFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "checksConfigurationPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Graph",
        "kind": "LinkedField",
        "name": "graphByAccountSlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "GraphOperationCheckConfiguration",
            "kind": "LinkedField",
            "name": "operationChecksConfiguration",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "excludedOperations",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "excludedClients",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "requestCountThreshold",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timeRangeDays",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Branch",
            "kind": "LinkedField",
            "name": "productionBranch",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BranchConnection",
            "kind": "LinkedField",
            "name": "branches",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Branch",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "58bed8bb7eccba24239e55c5da42b7ce",
    "id": null,
    "metadata": {},
    "name": "checksConfigurationPageQuery",
    "operationKind": "query",
    "text": "query checksConfigurationPageQuery(\n  $account: String!\n  $graph: String!\n) {\n  graphByAccountSlug(accountSlug: $account, graphSlug: $graph) {\n    ...excludedOperationsFragment\n    ...excludedClientsFragment\n    ...timeRangeFragment\n    ...branchesFragment\n    id\n  }\n}\n\nfragment branchItemChecksConfigurationFragment on Branch {\n  name\n  operationChecksEnabled\n}\n\nfragment branchesFragment on Graph {\n  productionBranch {\n    name\n    ...branchItemChecksConfigurationFragment\n    id\n  }\n  branches {\n    nodes {\n      name\n      ...branchItemChecksConfigurationFragment\n      id\n    }\n  }\n}\n\nfragment excludedClientsFragment on Graph {\n  operationChecksConfiguration {\n    excludedClients\n  }\n  id\n}\n\nfragment excludedOperationsFragment on Graph {\n  operationChecksConfiguration {\n    excludedOperations\n  }\n  id\n}\n\nfragment timeRangeFragment on Graph {\n  id\n  operationChecksConfiguration {\n    requestCountThreshold\n    timeRangeDays\n  }\n}\n"
  }
};
})();

(node as any).hash = "688cc85fe6647399f3c803050e555812";

export default node;
