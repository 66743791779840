/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './../app/routes/__root'
import { Route as SignUpImport } from './../app/routes/sign-up'
import { Route as SignInImport } from './../app/routes/sign-in'
import { Route as AuthTestRouteImport } from './../app/routes/auth-test-route'
import { Route as AdminImport } from './../app/routes/admin'
import { Route as DashboardImport } from './../app/routes/_dashboard'
import { Route as DashboardIndexImport } from './../app/routes/_dashboard.index'
import { Route as AuthTestingImport } from './../app/routes/auth.testing'
import { Route as AuthCliImport } from './../app/routes/auth.cli'
import { Route as DashboardNewOrganizationImport } from './../app/routes/_dashboard.new-organization'
import { Route as DashboardPersonalImport } from './../app/routes/_dashboard._personal'
import { Route as DashboardAccountImport } from './../app/routes/_dashboard.$account'
import { Route as DashboardAccountIndexImport } from './../app/routes/_dashboard.$account.index'
import { Route as DashboardInvitesIdImport } from './../app/routes/_dashboard.invites.$id'
import { Route as DashboardPersonalSettingsIndexImport } from './../app/routes/_dashboard._personal.settings.index'
import { Route as DashboardAccountSettingsIndexImport } from './../app/routes/_dashboard.$account.settings.index'
import { Route as DashboardPersonalSettingsDangerZoneImport } from './../app/routes/_dashboard._personal.settings.danger-zone'
import { Route as DashboardPersonalSettingsAccessTokensImport } from './../app/routes/_dashboard._personal.settings.access-tokens'
import { Route as DashboardAccountSettingsUsageImport } from './../app/routes/_dashboard.$account.settings.usage'
import { Route as DashboardAccountSettingsMembersImport } from './../app/routes/_dashboard.$account.settings.members'
import { Route as DashboardAccountSettingsDangerZoneImport } from './../app/routes/_dashboard.$account.settings.danger-zone'
import { Route as DashboardAccountSettingsAccessTokensImport } from './../app/routes/_dashboard.$account.settings.access-tokens'
import { Route as DashboardAccountSettingsTeamsIndexImport } from './../app/routes/_dashboard.$account.settings.teams.index'
import { Route as DashboardAccountGraphBranchesBranchImport } from './../app/routes/_dashboard.$account.$graph.branches.$branch'
import { Route as DashboardAccountGraphBranchesBranchIndexImport } from './../app/routes/_dashboard.$account.$graph.branches.$branch.index'
import { Route as DashboardAccountSettingsTeamsTeamTeamImport } from './../app/routes/_dashboard.$account.settings.teams.$team._team'
import { Route as DashboardAccountGraphBranchesBranchTracesImport } from './../app/routes/_dashboard.$account.$graph.branches.$branch.traces'
import { Route as DashboardAccountGraphBranchesBranchSubgraphsImport } from './../app/routes/_dashboard.$account.$graph.branches.$branch.subgraphs'
import { Route as DashboardAccountGraphBranchesBranchSchemaImport } from './../app/routes/_dashboard.$account.$graph.branches.$branch.schema'
import { Route as DashboardAccountGraphBranchesBranchPathfinderImport } from './../app/routes/_dashboard.$account.$graph.branches.$branch.pathfinder'
import { Route as DashboardAccountGraphBranchesBranchAnalyticsImport } from './../app/routes/_dashboard.$account.$graph.branches.$branch.analytics'
import { Route as DashboardAccountGraphBranchesBranchSchemaIndexImport } from './../app/routes/_dashboard.$account.$graph.branches.$branch.schema.index'
import { Route as DashboardAccountGraphBranchesBranchProposalsIndexImport } from './../app/routes/_dashboard.$account.$graph.branches.$branch.proposals.index'
import { Route as DashboardAccountGraphBranchesBranchDeploymentsIndexImport } from './../app/routes/_dashboard.$account.$graph.branches.$branch.deployments.index'
import { Route as DashboardAccountGraphBranchesBranchChecksIndexImport } from './../app/routes/_dashboard.$account.$graph.branches.$branch.checks.index'
import { Route as DashboardAccountGraphBranchesBranchChangelogIndexImport } from './../app/routes/_dashboard.$account.$graph.branches.$branch.changelog.index'
import { Route as DashboardAccountSettingsTeamsTeamTeamTeamsImport } from './../app/routes/_dashboard.$account.settings.teams.$team._team.teams'
import { Route as DashboardAccountSettingsTeamsTeamTeamSettingsImport } from './../app/routes/_dashboard.$account.settings.teams.$team._team.settings'
import { Route as DashboardAccountSettingsTeamsTeamTeamMembersImport } from './../app/routes/_dashboard.$account.settings.teams.$team._team.members'
import { Route as DashboardAccountSettingsTeamsTeamTeamGraphsImport } from './../app/routes/_dashboard.$account.settings.teams.$team._team.graphs'
import { Route as DashboardAccountGraphBranchesBranchTracesIdImport } from './../app/routes/_dashboard.$account.$graph.branches.$branch.traces.$id'
import { Route as DashboardAccountGraphBranchesBranchSchemaSdlImport } from './../app/routes/_dashboard.$account.$graph.branches.$branch.schema.sdl'
import { Route as DashboardAccountGraphBranchesBranchDeploymentsIdImport } from './../app/routes/_dashboard.$account.$graph.branches.$branch.deployments.$id'
import { Route as DashboardAccountGraphBranchesBranchChecksIdImport } from './../app/routes/_dashboard.$account.$graph.branches.$branch.checks.$id'
import { Route as DashboardAccountGraphBranchesBranchChangelogVersionImport } from './../app/routes/_dashboard.$account.$graph.branches.$branch.changelog.$version'
import { Route as DashboardAccountGraphBranchesBranchSettingsGraphIndexImport } from './../app/routes/_dashboard.$account.$graph.branches.$branch.settings.graph.index'
import { Route as DashboardAccountGraphBranchesBranchSchemaSdlIndexImport } from './../app/routes/_dashboard.$account.$graph.branches.$branch.schema.sdl.index'
import { Route as DashboardAccountGraphBranchesBranchSettingsGraphDangerZoneImport } from './../app/routes/_dashboard.$account.$graph.branches.$branch.settings.graph.danger-zone'
import { Route as DashboardAccountGraphBranchesBranchSettingsGraphChecksImport } from './../app/routes/_dashboard.$account.$graph.branches.$branch.settings.graph.checks'
import { Route as DashboardAccountGraphBranchesBranchSettingsGraphBranchesImport } from './../app/routes/_dashboard.$account.$graph.branches.$branch.settings.graph.branches'
import { Route as DashboardAccountGraphBranchesBranchSettingsBranchProposalsImport } from './../app/routes/_dashboard.$account.$graph.branches.$branch.settings.branch.proposals'
import { Route as DashboardAccountGraphBranchesBranchSettingsBranchPathfinderImport } from './../app/routes/_dashboard.$account.$graph.branches.$branch.settings.branch.pathfinder'
import { Route as DashboardAccountGraphBranchesBranchSettingsBranchDangerZoneImport } from './../app/routes/_dashboard.$account.$graph.branches.$branch.settings.branch.danger-zone'
import { Route as DashboardAccountGraphBranchesBranchSchemaSdlFederatedSchemaImport } from './../app/routes/_dashboard.$account.$graph.branches.$branch.schema.sdl.federated-schema'
import { Route as DashboardAccountGraphBranchesBranchProposalsIdProposalImport } from './../app/routes/_dashboard.$account.$graph.branches.$branch.proposals.$id._proposal'
import { Route as DashboardAccountGraphBranchesBranchSettingsGraphChecksIndexImport } from './../app/routes/_dashboard.$account.$graph.branches.$branch.settings.graph.checks.index'
import { Route as DashboardAccountGraphBranchesBranchProposalsIdProposalIndexImport } from './../app/routes/_dashboard.$account.$graph.branches.$branch.proposals.$id._proposal.index'
import { Route as DashboardAccountGraphBranchesBranchSchemaSdlSubgraphSubgraphImport } from './../app/routes/_dashboard.$account.$graph.branches.$branch.schema.sdl.subgraph.$subgraph'
import { Route as DashboardAccountGraphBranchesBranchProposalsIdProposalOverviewImport } from './../app/routes/_dashboard.$account.$graph.branches.$branch.proposals.$id._proposal.overview'
import { Route as DashboardAccountGraphBranchesBranchProposalsIdProposalEditorImport } from './../app/routes/_dashboard.$account.$graph.branches.$branch.proposals.$id._proposal.editor'
import { Route as DashboardAccountGraphBranchesBranchProposalsIdProposalDiffImport } from './../app/routes/_dashboard.$account.$graph.branches.$branch.proposals.$id._proposal.diff'

// Create Virtual Routes

const DashboardAccountSettingsTeamsTeamImport = createFileRoute(
  '/_dashboard/$account/settings/teams/$team',
)()
const DashboardAccountGraphBranchesBranchProposalsIdImport = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/proposals/$id',
)()

// Create/Update Routes

const SignUpRoute = SignUpImport.update({
  id: '/sign-up',
  path: '/sign-up',
  getParentRoute: () => rootRoute,
} as any)

const SignInRoute = SignInImport.update({
  id: '/sign-in',
  path: '/sign-in',
  getParentRoute: () => rootRoute,
} as any)

const AuthTestRouteRoute = AuthTestRouteImport.update({
  id: '/auth-test-route',
  path: '/auth-test-route',
  getParentRoute: () => rootRoute,
} as any)

const AdminRoute = AdminImport.update({
  id: '/admin',
  path: '/admin',
  getParentRoute: () => rootRoute,
} as any)

const DashboardRoute = DashboardImport.update({
  id: '/_dashboard',
  getParentRoute: () => rootRoute,
} as any)

const DashboardIndexRoute = DashboardIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => DashboardRoute,
} as any)

const AuthTestingRoute = AuthTestingImport.update({
  id: '/auth/testing',
  path: '/auth/testing',
  getParentRoute: () => rootRoute,
} as any)

const AuthCliRoute = AuthCliImport.update({
  id: '/auth/cli',
  path: '/auth/cli',
  getParentRoute: () => rootRoute,
} as any)

const DashboardNewOrganizationRoute = DashboardNewOrganizationImport.update({
  id: '/new-organization',
  path: '/new-organization',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardPersonalRoute = DashboardPersonalImport.update({
  id: '/_personal',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardAccountRoute = DashboardAccountImport.update({
  id: '/$account',
  path: '/$account',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardAccountIndexRoute = DashboardAccountIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => DashboardAccountRoute,
} as any)

const DashboardInvitesIdRoute = DashboardInvitesIdImport.update({
  id: '/invites/$id',
  path: '/invites/$id',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardPersonalSettingsIndexRoute =
  DashboardPersonalSettingsIndexImport.update({
    id: '/settings/',
    path: '/settings/',
    getParentRoute: () => DashboardPersonalRoute,
  } as any)

const DashboardAccountSettingsIndexRoute =
  DashboardAccountSettingsIndexImport.update({
    id: '/settings/',
    path: '/settings/',
    getParentRoute: () => DashboardAccountRoute,
  } as any)

const DashboardPersonalSettingsDangerZoneRoute =
  DashboardPersonalSettingsDangerZoneImport.update({
    id: '/settings/danger-zone',
    path: '/settings/danger-zone',
    getParentRoute: () => DashboardPersonalRoute,
  } as any)

const DashboardPersonalSettingsAccessTokensRoute =
  DashboardPersonalSettingsAccessTokensImport.update({
    id: '/settings/access-tokens',
    path: '/settings/access-tokens',
    getParentRoute: () => DashboardPersonalRoute,
  } as any)

const DashboardAccountSettingsUsageRoute =
  DashboardAccountSettingsUsageImport.update({
    id: '/settings/usage',
    path: '/settings/usage',
    getParentRoute: () => DashboardAccountRoute,
  } as any)

const DashboardAccountSettingsMembersRoute =
  DashboardAccountSettingsMembersImport.update({
    id: '/settings/members',
    path: '/settings/members',
    getParentRoute: () => DashboardAccountRoute,
  } as any)

const DashboardAccountSettingsDangerZoneRoute =
  DashboardAccountSettingsDangerZoneImport.update({
    id: '/settings/danger-zone',
    path: '/settings/danger-zone',
    getParentRoute: () => DashboardAccountRoute,
  } as any)

const DashboardAccountSettingsAccessTokensRoute =
  DashboardAccountSettingsAccessTokensImport.update({
    id: '/settings/access-tokens',
    path: '/settings/access-tokens',
    getParentRoute: () => DashboardAccountRoute,
  } as any)

const DashboardAccountSettingsTeamsTeamRoute =
  DashboardAccountSettingsTeamsTeamImport.update({
    id: '/settings/teams/$team',
    path: '/settings/teams/$team',
    getParentRoute: () => DashboardAccountRoute,
  } as any)

const DashboardAccountSettingsTeamsIndexRoute =
  DashboardAccountSettingsTeamsIndexImport.update({
    id: '/settings/teams/',
    path: '/settings/teams/',
    getParentRoute: () => DashboardAccountRoute,
  } as any)

const DashboardAccountGraphBranchesBranchRoute =
  DashboardAccountGraphBranchesBranchImport.update({
    id: '/$graph/branches/$branch',
    path: '/$graph/branches/$branch',
    getParentRoute: () => DashboardAccountRoute,
  } as any)

const DashboardAccountGraphBranchesBranchIndexRoute =
  DashboardAccountGraphBranchesBranchIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => DashboardAccountGraphBranchesBranchRoute,
  } as any)

const DashboardAccountSettingsTeamsTeamTeamRoute =
  DashboardAccountSettingsTeamsTeamTeamImport.update({
    id: '/_team',
    getParentRoute: () => DashboardAccountSettingsTeamsTeamRoute,
  } as any)

const DashboardAccountGraphBranchesBranchTracesRoute =
  DashboardAccountGraphBranchesBranchTracesImport.update({
    id: '/traces',
    path: '/traces',
    getParentRoute: () => DashboardAccountGraphBranchesBranchRoute,
  } as any)

const DashboardAccountGraphBranchesBranchSubgraphsRoute =
  DashboardAccountGraphBranchesBranchSubgraphsImport.update({
    id: '/subgraphs',
    path: '/subgraphs',
    getParentRoute: () => DashboardAccountGraphBranchesBranchRoute,
  } as any)

const DashboardAccountGraphBranchesBranchSchemaRoute =
  DashboardAccountGraphBranchesBranchSchemaImport.update({
    id: '/schema',
    path: '/schema',
    getParentRoute: () => DashboardAccountGraphBranchesBranchRoute,
  } as any)

const DashboardAccountGraphBranchesBranchPathfinderRoute =
  DashboardAccountGraphBranchesBranchPathfinderImport.update({
    id: '/pathfinder',
    path: '/pathfinder',
    getParentRoute: () => DashboardAccountGraphBranchesBranchRoute,
  } as any)

const DashboardAccountGraphBranchesBranchAnalyticsRoute =
  DashboardAccountGraphBranchesBranchAnalyticsImport.update({
    id: '/analytics',
    path: '/analytics',
    getParentRoute: () => DashboardAccountGraphBranchesBranchRoute,
  } as any)

const DashboardAccountGraphBranchesBranchProposalsIdRoute =
  DashboardAccountGraphBranchesBranchProposalsIdImport.update({
    id: '/proposals/$id',
    path: '/proposals/$id',
    getParentRoute: () => DashboardAccountGraphBranchesBranchRoute,
  } as any)

const DashboardAccountGraphBranchesBranchSchemaIndexRoute =
  DashboardAccountGraphBranchesBranchSchemaIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => DashboardAccountGraphBranchesBranchSchemaRoute,
  } as any)

const DashboardAccountGraphBranchesBranchProposalsIndexRoute =
  DashboardAccountGraphBranchesBranchProposalsIndexImport.update({
    id: '/proposals/',
    path: '/proposals/',
    getParentRoute: () => DashboardAccountGraphBranchesBranchRoute,
  } as any)

const DashboardAccountGraphBranchesBranchDeploymentsIndexRoute =
  DashboardAccountGraphBranchesBranchDeploymentsIndexImport.update({
    id: '/deployments/',
    path: '/deployments/',
    getParentRoute: () => DashboardAccountGraphBranchesBranchRoute,
  } as any)

const DashboardAccountGraphBranchesBranchChecksIndexRoute =
  DashboardAccountGraphBranchesBranchChecksIndexImport.update({
    id: '/checks/',
    path: '/checks/',
    getParentRoute: () => DashboardAccountGraphBranchesBranchRoute,
  } as any)

const DashboardAccountGraphBranchesBranchChangelogIndexRoute =
  DashboardAccountGraphBranchesBranchChangelogIndexImport.update({
    id: '/changelog/',
    path: '/changelog/',
    getParentRoute: () => DashboardAccountGraphBranchesBranchRoute,
  } as any)

const DashboardAccountSettingsTeamsTeamTeamTeamsRoute =
  DashboardAccountSettingsTeamsTeamTeamTeamsImport.update({
    id: '/teams',
    path: '/teams',
    getParentRoute: () => DashboardAccountSettingsTeamsTeamTeamRoute,
  } as any)

const DashboardAccountSettingsTeamsTeamTeamSettingsRoute =
  DashboardAccountSettingsTeamsTeamTeamSettingsImport.update({
    id: '/settings',
    path: '/settings',
    getParentRoute: () => DashboardAccountSettingsTeamsTeamTeamRoute,
  } as any)

const DashboardAccountSettingsTeamsTeamTeamMembersRoute =
  DashboardAccountSettingsTeamsTeamTeamMembersImport.update({
    id: '/members',
    path: '/members',
    getParentRoute: () => DashboardAccountSettingsTeamsTeamTeamRoute,
  } as any)

const DashboardAccountSettingsTeamsTeamTeamGraphsRoute =
  DashboardAccountSettingsTeamsTeamTeamGraphsImport.update({
    id: '/graphs',
    path: '/graphs',
    getParentRoute: () => DashboardAccountSettingsTeamsTeamTeamRoute,
  } as any)

const DashboardAccountGraphBranchesBranchTracesIdRoute =
  DashboardAccountGraphBranchesBranchTracesIdImport.update({
    id: '/$id',
    path: '/$id',
    getParentRoute: () => DashboardAccountGraphBranchesBranchTracesRoute,
  } as any)

const DashboardAccountGraphBranchesBranchSchemaSdlRoute =
  DashboardAccountGraphBranchesBranchSchemaSdlImport.update({
    id: '/sdl',
    path: '/sdl',
    getParentRoute: () => DashboardAccountGraphBranchesBranchSchemaRoute,
  } as any)

const DashboardAccountGraphBranchesBranchDeploymentsIdRoute =
  DashboardAccountGraphBranchesBranchDeploymentsIdImport.update({
    id: '/deployments/$id',
    path: '/deployments/$id',
    getParentRoute: () => DashboardAccountGraphBranchesBranchRoute,
  } as any)

const DashboardAccountGraphBranchesBranchChecksIdRoute =
  DashboardAccountGraphBranchesBranchChecksIdImport.update({
    id: '/checks/$id',
    path: '/checks/$id',
    getParentRoute: () => DashboardAccountGraphBranchesBranchRoute,
  } as any)

const DashboardAccountGraphBranchesBranchChangelogVersionRoute =
  DashboardAccountGraphBranchesBranchChangelogVersionImport.update({
    id: '/changelog/$version',
    path: '/changelog/$version',
    getParentRoute: () => DashboardAccountGraphBranchesBranchRoute,
  } as any)

const DashboardAccountGraphBranchesBranchSettingsGraphIndexRoute =
  DashboardAccountGraphBranchesBranchSettingsGraphIndexImport.update({
    id: '/settings/graph/',
    path: '/settings/graph/',
    getParentRoute: () => DashboardAccountGraphBranchesBranchRoute,
  } as any)

const DashboardAccountGraphBranchesBranchSchemaSdlIndexRoute =
  DashboardAccountGraphBranchesBranchSchemaSdlIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => DashboardAccountGraphBranchesBranchSchemaSdlRoute,
  } as any)

const DashboardAccountGraphBranchesBranchSettingsGraphDangerZoneRoute =
  DashboardAccountGraphBranchesBranchSettingsGraphDangerZoneImport.update({
    id: '/settings/graph/danger-zone',
    path: '/settings/graph/danger-zone',
    getParentRoute: () => DashboardAccountGraphBranchesBranchRoute,
  } as any)

const DashboardAccountGraphBranchesBranchSettingsGraphChecksRoute =
  DashboardAccountGraphBranchesBranchSettingsGraphChecksImport.update({
    id: '/settings/graph/checks',
    path: '/settings/graph/checks',
    getParentRoute: () => DashboardAccountGraphBranchesBranchRoute,
  } as any)

const DashboardAccountGraphBranchesBranchSettingsGraphBranchesRoute =
  DashboardAccountGraphBranchesBranchSettingsGraphBranchesImport.update({
    id: '/settings/graph/branches',
    path: '/settings/graph/branches',
    getParentRoute: () => DashboardAccountGraphBranchesBranchRoute,
  } as any)

const DashboardAccountGraphBranchesBranchSettingsBranchProposalsRoute =
  DashboardAccountGraphBranchesBranchSettingsBranchProposalsImport.update({
    id: '/settings/branch/proposals',
    path: '/settings/branch/proposals',
    getParentRoute: () => DashboardAccountGraphBranchesBranchRoute,
  } as any)

const DashboardAccountGraphBranchesBranchSettingsBranchPathfinderRoute =
  DashboardAccountGraphBranchesBranchSettingsBranchPathfinderImport.update({
    id: '/settings/branch/pathfinder',
    path: '/settings/branch/pathfinder',
    getParentRoute: () => DashboardAccountGraphBranchesBranchRoute,
  } as any)

const DashboardAccountGraphBranchesBranchSettingsBranchDangerZoneRoute =
  DashboardAccountGraphBranchesBranchSettingsBranchDangerZoneImport.update({
    id: '/settings/branch/danger-zone',
    path: '/settings/branch/danger-zone',
    getParentRoute: () => DashboardAccountGraphBranchesBranchRoute,
  } as any)

const DashboardAccountGraphBranchesBranchSchemaSdlFederatedSchemaRoute =
  DashboardAccountGraphBranchesBranchSchemaSdlFederatedSchemaImport.update({
    id: '/federated-schema',
    path: '/federated-schema',
    getParentRoute: () => DashboardAccountGraphBranchesBranchSchemaSdlRoute,
  } as any)

const DashboardAccountGraphBranchesBranchProposalsIdProposalRoute =
  DashboardAccountGraphBranchesBranchProposalsIdProposalImport.update({
    id: '/_proposal',
    getParentRoute: () => DashboardAccountGraphBranchesBranchProposalsIdRoute,
  } as any)

const DashboardAccountGraphBranchesBranchSettingsGraphChecksIndexRoute =
  DashboardAccountGraphBranchesBranchSettingsGraphChecksIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () =>
      DashboardAccountGraphBranchesBranchSettingsGraphChecksRoute,
  } as any)

const DashboardAccountGraphBranchesBranchProposalsIdProposalIndexRoute =
  DashboardAccountGraphBranchesBranchProposalsIdProposalIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () =>
      DashboardAccountGraphBranchesBranchProposalsIdProposalRoute,
  } as any)

const DashboardAccountGraphBranchesBranchSchemaSdlSubgraphSubgraphRoute =
  DashboardAccountGraphBranchesBranchSchemaSdlSubgraphSubgraphImport.update({
    id: '/subgraph/$subgraph',
    path: '/subgraph/$subgraph',
    getParentRoute: () => DashboardAccountGraphBranchesBranchSchemaSdlRoute,
  } as any)

const DashboardAccountGraphBranchesBranchProposalsIdProposalOverviewRoute =
  DashboardAccountGraphBranchesBranchProposalsIdProposalOverviewImport.update({
    id: '/overview',
    path: '/overview',
    getParentRoute: () =>
      DashboardAccountGraphBranchesBranchProposalsIdProposalRoute,
  } as any)

const DashboardAccountGraphBranchesBranchProposalsIdProposalEditorRoute =
  DashboardAccountGraphBranchesBranchProposalsIdProposalEditorImport.update({
    id: '/editor',
    path: '/editor',
    getParentRoute: () =>
      DashboardAccountGraphBranchesBranchProposalsIdProposalRoute,
  } as any)

const DashboardAccountGraphBranchesBranchProposalsIdProposalDiffRoute =
  DashboardAccountGraphBranchesBranchProposalsIdProposalDiffImport.update({
    id: '/diff',
    path: '/diff',
    getParentRoute: () =>
      DashboardAccountGraphBranchesBranchProposalsIdProposalRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_dashboard': {
      id: '/_dashboard'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof DashboardImport
      parentRoute: typeof rootRoute
    }
    '/admin': {
      id: '/admin'
      path: '/admin'
      fullPath: '/admin'
      preLoaderRoute: typeof AdminImport
      parentRoute: typeof rootRoute
    }
    '/auth-test-route': {
      id: '/auth-test-route'
      path: '/auth-test-route'
      fullPath: '/auth-test-route'
      preLoaderRoute: typeof AuthTestRouteImport
      parentRoute: typeof rootRoute
    }
    '/sign-in': {
      id: '/sign-in'
      path: '/sign-in'
      fullPath: '/sign-in'
      preLoaderRoute: typeof SignInImport
      parentRoute: typeof rootRoute
    }
    '/sign-up': {
      id: '/sign-up'
      path: '/sign-up'
      fullPath: '/sign-up'
      preLoaderRoute: typeof SignUpImport
      parentRoute: typeof rootRoute
    }
    '/_dashboard/$account': {
      id: '/_dashboard/$account'
      path: '/$account'
      fullPath: '/$account'
      preLoaderRoute: typeof DashboardAccountImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/_personal': {
      id: '/_dashboard/_personal'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof DashboardPersonalImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/new-organization': {
      id: '/_dashboard/new-organization'
      path: '/new-organization'
      fullPath: '/new-organization'
      preLoaderRoute: typeof DashboardNewOrganizationImport
      parentRoute: typeof DashboardImport
    }
    '/auth/cli': {
      id: '/auth/cli'
      path: '/auth/cli'
      fullPath: '/auth/cli'
      preLoaderRoute: typeof AuthCliImport
      parentRoute: typeof rootRoute
    }
    '/auth/testing': {
      id: '/auth/testing'
      path: '/auth/testing'
      fullPath: '/auth/testing'
      preLoaderRoute: typeof AuthTestingImport
      parentRoute: typeof rootRoute
    }
    '/_dashboard/': {
      id: '/_dashboard/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof DashboardIndexImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/invites/$id': {
      id: '/_dashboard/invites/$id'
      path: '/invites/$id'
      fullPath: '/invites/$id'
      preLoaderRoute: typeof DashboardInvitesIdImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/$account/': {
      id: '/_dashboard/$account/'
      path: '/'
      fullPath: '/$account/'
      preLoaderRoute: typeof DashboardAccountIndexImport
      parentRoute: typeof DashboardAccountImport
    }
    '/_dashboard/$account/settings/access-tokens': {
      id: '/_dashboard/$account/settings/access-tokens'
      path: '/settings/access-tokens'
      fullPath: '/$account/settings/access-tokens'
      preLoaderRoute: typeof DashboardAccountSettingsAccessTokensImport
      parentRoute: typeof DashboardAccountImport
    }
    '/_dashboard/$account/settings/danger-zone': {
      id: '/_dashboard/$account/settings/danger-zone'
      path: '/settings/danger-zone'
      fullPath: '/$account/settings/danger-zone'
      preLoaderRoute: typeof DashboardAccountSettingsDangerZoneImport
      parentRoute: typeof DashboardAccountImport
    }
    '/_dashboard/$account/settings/members': {
      id: '/_dashboard/$account/settings/members'
      path: '/settings/members'
      fullPath: '/$account/settings/members'
      preLoaderRoute: typeof DashboardAccountSettingsMembersImport
      parentRoute: typeof DashboardAccountImport
    }
    '/_dashboard/$account/settings/usage': {
      id: '/_dashboard/$account/settings/usage'
      path: '/settings/usage'
      fullPath: '/$account/settings/usage'
      preLoaderRoute: typeof DashboardAccountSettingsUsageImport
      parentRoute: typeof DashboardAccountImport
    }
    '/_dashboard/_personal/settings/access-tokens': {
      id: '/_dashboard/_personal/settings/access-tokens'
      path: '/settings/access-tokens'
      fullPath: '/settings/access-tokens'
      preLoaderRoute: typeof DashboardPersonalSettingsAccessTokensImport
      parentRoute: typeof DashboardPersonalImport
    }
    '/_dashboard/_personal/settings/danger-zone': {
      id: '/_dashboard/_personal/settings/danger-zone'
      path: '/settings/danger-zone'
      fullPath: '/settings/danger-zone'
      preLoaderRoute: typeof DashboardPersonalSettingsDangerZoneImport
      parentRoute: typeof DashboardPersonalImport
    }
    '/_dashboard/$account/settings/': {
      id: '/_dashboard/$account/settings/'
      path: '/settings'
      fullPath: '/$account/settings'
      preLoaderRoute: typeof DashboardAccountSettingsIndexImport
      parentRoute: typeof DashboardAccountImport
    }
    '/_dashboard/_personal/settings/': {
      id: '/_dashboard/_personal/settings/'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof DashboardPersonalSettingsIndexImport
      parentRoute: typeof DashboardPersonalImport
    }
    '/_dashboard/$account/$graph/branches/$branch': {
      id: '/_dashboard/$account/$graph/branches/$branch'
      path: '/$graph/branches/$branch'
      fullPath: '/$account/$graph/branches/$branch'
      preLoaderRoute: typeof DashboardAccountGraphBranchesBranchImport
      parentRoute: typeof DashboardAccountImport
    }
    '/_dashboard/$account/settings/teams/': {
      id: '/_dashboard/$account/settings/teams/'
      path: '/settings/teams'
      fullPath: '/$account/settings/teams'
      preLoaderRoute: typeof DashboardAccountSettingsTeamsIndexImport
      parentRoute: typeof DashboardAccountImport
    }
    '/_dashboard/$account/$graph/branches/$branch/analytics': {
      id: '/_dashboard/$account/$graph/branches/$branch/analytics'
      path: '/analytics'
      fullPath: '/$account/$graph/branches/$branch/analytics'
      preLoaderRoute: typeof DashboardAccountGraphBranchesBranchAnalyticsImport
      parentRoute: typeof DashboardAccountGraphBranchesBranchImport
    }
    '/_dashboard/$account/$graph/branches/$branch/pathfinder': {
      id: '/_dashboard/$account/$graph/branches/$branch/pathfinder'
      path: '/pathfinder'
      fullPath: '/$account/$graph/branches/$branch/pathfinder'
      preLoaderRoute: typeof DashboardAccountGraphBranchesBranchPathfinderImport
      parentRoute: typeof DashboardAccountGraphBranchesBranchImport
    }
    '/_dashboard/$account/$graph/branches/$branch/schema': {
      id: '/_dashboard/$account/$graph/branches/$branch/schema'
      path: '/schema'
      fullPath: '/$account/$graph/branches/$branch/schema'
      preLoaderRoute: typeof DashboardAccountGraphBranchesBranchSchemaImport
      parentRoute: typeof DashboardAccountGraphBranchesBranchImport
    }
    '/_dashboard/$account/$graph/branches/$branch/subgraphs': {
      id: '/_dashboard/$account/$graph/branches/$branch/subgraphs'
      path: '/subgraphs'
      fullPath: '/$account/$graph/branches/$branch/subgraphs'
      preLoaderRoute: typeof DashboardAccountGraphBranchesBranchSubgraphsImport
      parentRoute: typeof DashboardAccountGraphBranchesBranchImport
    }
    '/_dashboard/$account/$graph/branches/$branch/traces': {
      id: '/_dashboard/$account/$graph/branches/$branch/traces'
      path: '/traces'
      fullPath: '/$account/$graph/branches/$branch/traces'
      preLoaderRoute: typeof DashboardAccountGraphBranchesBranchTracesImport
      parentRoute: typeof DashboardAccountGraphBranchesBranchImport
    }
    '/_dashboard/$account/settings/teams/$team': {
      id: '/_dashboard/$account/settings/teams/$team'
      path: '/settings/teams/$team'
      fullPath: '/$account/settings/teams/$team'
      preLoaderRoute: typeof DashboardAccountSettingsTeamsTeamImport
      parentRoute: typeof DashboardAccountImport
    }
    '/_dashboard/$account/settings/teams/$team/_team': {
      id: '/_dashboard/$account/settings/teams/$team/_team'
      path: '/settings/teams/$team'
      fullPath: '/$account/settings/teams/$team'
      preLoaderRoute: typeof DashboardAccountSettingsTeamsTeamTeamImport
      parentRoute: typeof DashboardAccountSettingsTeamsTeamRoute
    }
    '/_dashboard/$account/$graph/branches/$branch/': {
      id: '/_dashboard/$account/$graph/branches/$branch/'
      path: '/'
      fullPath: '/$account/$graph/branches/$branch/'
      preLoaderRoute: typeof DashboardAccountGraphBranchesBranchIndexImport
      parentRoute: typeof DashboardAccountGraphBranchesBranchImport
    }
    '/_dashboard/$account/$graph/branches/$branch/changelog/$version': {
      id: '/_dashboard/$account/$graph/branches/$branch/changelog/$version'
      path: '/changelog/$version'
      fullPath: '/$account/$graph/branches/$branch/changelog/$version'
      preLoaderRoute: typeof DashboardAccountGraphBranchesBranchChangelogVersionImport
      parentRoute: typeof DashboardAccountGraphBranchesBranchImport
    }
    '/_dashboard/$account/$graph/branches/$branch/checks/$id': {
      id: '/_dashboard/$account/$graph/branches/$branch/checks/$id'
      path: '/checks/$id'
      fullPath: '/$account/$graph/branches/$branch/checks/$id'
      preLoaderRoute: typeof DashboardAccountGraphBranchesBranchChecksIdImport
      parentRoute: typeof DashboardAccountGraphBranchesBranchImport
    }
    '/_dashboard/$account/$graph/branches/$branch/deployments/$id': {
      id: '/_dashboard/$account/$graph/branches/$branch/deployments/$id'
      path: '/deployments/$id'
      fullPath: '/$account/$graph/branches/$branch/deployments/$id'
      preLoaderRoute: typeof DashboardAccountGraphBranchesBranchDeploymentsIdImport
      parentRoute: typeof DashboardAccountGraphBranchesBranchImport
    }
    '/_dashboard/$account/$graph/branches/$branch/schema/sdl': {
      id: '/_dashboard/$account/$graph/branches/$branch/schema/sdl'
      path: '/sdl'
      fullPath: '/$account/$graph/branches/$branch/schema/sdl'
      preLoaderRoute: typeof DashboardAccountGraphBranchesBranchSchemaSdlImport
      parentRoute: typeof DashboardAccountGraphBranchesBranchSchemaImport
    }
    '/_dashboard/$account/$graph/branches/$branch/traces/$id': {
      id: '/_dashboard/$account/$graph/branches/$branch/traces/$id'
      path: '/$id'
      fullPath: '/$account/$graph/branches/$branch/traces/$id'
      preLoaderRoute: typeof DashboardAccountGraphBranchesBranchTracesIdImport
      parentRoute: typeof DashboardAccountGraphBranchesBranchTracesImport
    }
    '/_dashboard/$account/settings/teams/$team/_team/graphs': {
      id: '/_dashboard/$account/settings/teams/$team/_team/graphs'
      path: '/graphs'
      fullPath: '/$account/settings/teams/$team/graphs'
      preLoaderRoute: typeof DashboardAccountSettingsTeamsTeamTeamGraphsImport
      parentRoute: typeof DashboardAccountSettingsTeamsTeamTeamImport
    }
    '/_dashboard/$account/settings/teams/$team/_team/members': {
      id: '/_dashboard/$account/settings/teams/$team/_team/members'
      path: '/members'
      fullPath: '/$account/settings/teams/$team/members'
      preLoaderRoute: typeof DashboardAccountSettingsTeamsTeamTeamMembersImport
      parentRoute: typeof DashboardAccountSettingsTeamsTeamTeamImport
    }
    '/_dashboard/$account/settings/teams/$team/_team/settings': {
      id: '/_dashboard/$account/settings/teams/$team/_team/settings'
      path: '/settings'
      fullPath: '/$account/settings/teams/$team/settings'
      preLoaderRoute: typeof DashboardAccountSettingsTeamsTeamTeamSettingsImport
      parentRoute: typeof DashboardAccountSettingsTeamsTeamTeamImport
    }
    '/_dashboard/$account/settings/teams/$team/_team/teams': {
      id: '/_dashboard/$account/settings/teams/$team/_team/teams'
      path: '/teams'
      fullPath: '/$account/settings/teams/$team/teams'
      preLoaderRoute: typeof DashboardAccountSettingsTeamsTeamTeamTeamsImport
      parentRoute: typeof DashboardAccountSettingsTeamsTeamTeamImport
    }
    '/_dashboard/$account/$graph/branches/$branch/changelog/': {
      id: '/_dashboard/$account/$graph/branches/$branch/changelog/'
      path: '/changelog'
      fullPath: '/$account/$graph/branches/$branch/changelog'
      preLoaderRoute: typeof DashboardAccountGraphBranchesBranchChangelogIndexImport
      parentRoute: typeof DashboardAccountGraphBranchesBranchImport
    }
    '/_dashboard/$account/$graph/branches/$branch/checks/': {
      id: '/_dashboard/$account/$graph/branches/$branch/checks/'
      path: '/checks'
      fullPath: '/$account/$graph/branches/$branch/checks'
      preLoaderRoute: typeof DashboardAccountGraphBranchesBranchChecksIndexImport
      parentRoute: typeof DashboardAccountGraphBranchesBranchImport
    }
    '/_dashboard/$account/$graph/branches/$branch/deployments/': {
      id: '/_dashboard/$account/$graph/branches/$branch/deployments/'
      path: '/deployments'
      fullPath: '/$account/$graph/branches/$branch/deployments'
      preLoaderRoute: typeof DashboardAccountGraphBranchesBranchDeploymentsIndexImport
      parentRoute: typeof DashboardAccountGraphBranchesBranchImport
    }
    '/_dashboard/$account/$graph/branches/$branch/proposals/': {
      id: '/_dashboard/$account/$graph/branches/$branch/proposals/'
      path: '/proposals'
      fullPath: '/$account/$graph/branches/$branch/proposals'
      preLoaderRoute: typeof DashboardAccountGraphBranchesBranchProposalsIndexImport
      parentRoute: typeof DashboardAccountGraphBranchesBranchImport
    }
    '/_dashboard/$account/$graph/branches/$branch/schema/': {
      id: '/_dashboard/$account/$graph/branches/$branch/schema/'
      path: '/'
      fullPath: '/$account/$graph/branches/$branch/schema/'
      preLoaderRoute: typeof DashboardAccountGraphBranchesBranchSchemaIndexImport
      parentRoute: typeof DashboardAccountGraphBranchesBranchSchemaImport
    }
    '/_dashboard/$account/$graph/branches/$branch/proposals/$id': {
      id: '/_dashboard/$account/$graph/branches/$branch/proposals/$id'
      path: '/proposals/$id'
      fullPath: '/$account/$graph/branches/$branch/proposals/$id'
      preLoaderRoute: typeof DashboardAccountGraphBranchesBranchProposalsIdImport
      parentRoute: typeof DashboardAccountGraphBranchesBranchImport
    }
    '/_dashboard/$account/$graph/branches/$branch/proposals/$id/_proposal': {
      id: '/_dashboard/$account/$graph/branches/$branch/proposals/$id/_proposal'
      path: '/proposals/$id'
      fullPath: '/$account/$graph/branches/$branch/proposals/$id'
      preLoaderRoute: typeof DashboardAccountGraphBranchesBranchProposalsIdProposalImport
      parentRoute: typeof DashboardAccountGraphBranchesBranchProposalsIdRoute
    }
    '/_dashboard/$account/$graph/branches/$branch/schema/sdl/federated-schema': {
      id: '/_dashboard/$account/$graph/branches/$branch/schema/sdl/federated-schema'
      path: '/federated-schema'
      fullPath: '/$account/$graph/branches/$branch/schema/sdl/federated-schema'
      preLoaderRoute: typeof DashboardAccountGraphBranchesBranchSchemaSdlFederatedSchemaImport
      parentRoute: typeof DashboardAccountGraphBranchesBranchSchemaSdlImport
    }
    '/_dashboard/$account/$graph/branches/$branch/settings/branch/danger-zone': {
      id: '/_dashboard/$account/$graph/branches/$branch/settings/branch/danger-zone'
      path: '/settings/branch/danger-zone'
      fullPath: '/$account/$graph/branches/$branch/settings/branch/danger-zone'
      preLoaderRoute: typeof DashboardAccountGraphBranchesBranchSettingsBranchDangerZoneImport
      parentRoute: typeof DashboardAccountGraphBranchesBranchImport
    }
    '/_dashboard/$account/$graph/branches/$branch/settings/branch/pathfinder': {
      id: '/_dashboard/$account/$graph/branches/$branch/settings/branch/pathfinder'
      path: '/settings/branch/pathfinder'
      fullPath: '/$account/$graph/branches/$branch/settings/branch/pathfinder'
      preLoaderRoute: typeof DashboardAccountGraphBranchesBranchSettingsBranchPathfinderImport
      parentRoute: typeof DashboardAccountGraphBranchesBranchImport
    }
    '/_dashboard/$account/$graph/branches/$branch/settings/branch/proposals': {
      id: '/_dashboard/$account/$graph/branches/$branch/settings/branch/proposals'
      path: '/settings/branch/proposals'
      fullPath: '/$account/$graph/branches/$branch/settings/branch/proposals'
      preLoaderRoute: typeof DashboardAccountGraphBranchesBranchSettingsBranchProposalsImport
      parentRoute: typeof DashboardAccountGraphBranchesBranchImport
    }
    '/_dashboard/$account/$graph/branches/$branch/settings/graph/branches': {
      id: '/_dashboard/$account/$graph/branches/$branch/settings/graph/branches'
      path: '/settings/graph/branches'
      fullPath: '/$account/$graph/branches/$branch/settings/graph/branches'
      preLoaderRoute: typeof DashboardAccountGraphBranchesBranchSettingsGraphBranchesImport
      parentRoute: typeof DashboardAccountGraphBranchesBranchImport
    }
    '/_dashboard/$account/$graph/branches/$branch/settings/graph/checks': {
      id: '/_dashboard/$account/$graph/branches/$branch/settings/graph/checks'
      path: '/settings/graph/checks'
      fullPath: '/$account/$graph/branches/$branch/settings/graph/checks'
      preLoaderRoute: typeof DashboardAccountGraphBranchesBranchSettingsGraphChecksImport
      parentRoute: typeof DashboardAccountGraphBranchesBranchImport
    }
    '/_dashboard/$account/$graph/branches/$branch/settings/graph/danger-zone': {
      id: '/_dashboard/$account/$graph/branches/$branch/settings/graph/danger-zone'
      path: '/settings/graph/danger-zone'
      fullPath: '/$account/$graph/branches/$branch/settings/graph/danger-zone'
      preLoaderRoute: typeof DashboardAccountGraphBranchesBranchSettingsGraphDangerZoneImport
      parentRoute: typeof DashboardAccountGraphBranchesBranchImport
    }
    '/_dashboard/$account/$graph/branches/$branch/schema/sdl/': {
      id: '/_dashboard/$account/$graph/branches/$branch/schema/sdl/'
      path: '/'
      fullPath: '/$account/$graph/branches/$branch/schema/sdl/'
      preLoaderRoute: typeof DashboardAccountGraphBranchesBranchSchemaSdlIndexImport
      parentRoute: typeof DashboardAccountGraphBranchesBranchSchemaSdlImport
    }
    '/_dashboard/$account/$graph/branches/$branch/settings/graph/': {
      id: '/_dashboard/$account/$graph/branches/$branch/settings/graph/'
      path: '/settings/graph'
      fullPath: '/$account/$graph/branches/$branch/settings/graph'
      preLoaderRoute: typeof DashboardAccountGraphBranchesBranchSettingsGraphIndexImport
      parentRoute: typeof DashboardAccountGraphBranchesBranchImport
    }
    '/_dashboard/$account/$graph/branches/$branch/proposals/$id/_proposal/diff': {
      id: '/_dashboard/$account/$graph/branches/$branch/proposals/$id/_proposal/diff'
      path: '/diff'
      fullPath: '/$account/$graph/branches/$branch/proposals/$id/diff'
      preLoaderRoute: typeof DashboardAccountGraphBranchesBranchProposalsIdProposalDiffImport
      parentRoute: typeof DashboardAccountGraphBranchesBranchProposalsIdProposalImport
    }
    '/_dashboard/$account/$graph/branches/$branch/proposals/$id/_proposal/editor': {
      id: '/_dashboard/$account/$graph/branches/$branch/proposals/$id/_proposal/editor'
      path: '/editor'
      fullPath: '/$account/$graph/branches/$branch/proposals/$id/editor'
      preLoaderRoute: typeof DashboardAccountGraphBranchesBranchProposalsIdProposalEditorImport
      parentRoute: typeof DashboardAccountGraphBranchesBranchProposalsIdProposalImport
    }
    '/_dashboard/$account/$graph/branches/$branch/proposals/$id/_proposal/overview': {
      id: '/_dashboard/$account/$graph/branches/$branch/proposals/$id/_proposal/overview'
      path: '/overview'
      fullPath: '/$account/$graph/branches/$branch/proposals/$id/overview'
      preLoaderRoute: typeof DashboardAccountGraphBranchesBranchProposalsIdProposalOverviewImport
      parentRoute: typeof DashboardAccountGraphBranchesBranchProposalsIdProposalImport
    }
    '/_dashboard/$account/$graph/branches/$branch/schema/sdl/subgraph/$subgraph': {
      id: '/_dashboard/$account/$graph/branches/$branch/schema/sdl/subgraph/$subgraph'
      path: '/subgraph/$subgraph'
      fullPath: '/$account/$graph/branches/$branch/schema/sdl/subgraph/$subgraph'
      preLoaderRoute: typeof DashboardAccountGraphBranchesBranchSchemaSdlSubgraphSubgraphImport
      parentRoute: typeof DashboardAccountGraphBranchesBranchSchemaSdlImport
    }
    '/_dashboard/$account/$graph/branches/$branch/proposals/$id/_proposal/': {
      id: '/_dashboard/$account/$graph/branches/$branch/proposals/$id/_proposal/'
      path: '/'
      fullPath: '/$account/$graph/branches/$branch/proposals/$id/'
      preLoaderRoute: typeof DashboardAccountGraphBranchesBranchProposalsIdProposalIndexImport
      parentRoute: typeof DashboardAccountGraphBranchesBranchProposalsIdProposalImport
    }
    '/_dashboard/$account/$graph/branches/$branch/settings/graph/checks/': {
      id: '/_dashboard/$account/$graph/branches/$branch/settings/graph/checks/'
      path: '/'
      fullPath: '/$account/$graph/branches/$branch/settings/graph/checks/'
      preLoaderRoute: typeof DashboardAccountGraphBranchesBranchSettingsGraphChecksIndexImport
      parentRoute: typeof DashboardAccountGraphBranchesBranchSettingsGraphChecksImport
    }
  }
}

// Create and export the route tree

interface DashboardAccountGraphBranchesBranchSchemaSdlRouteChildren {
  DashboardAccountGraphBranchesBranchSchemaSdlFederatedSchemaRoute: typeof DashboardAccountGraphBranchesBranchSchemaSdlFederatedSchemaRoute
  DashboardAccountGraphBranchesBranchSchemaSdlIndexRoute: typeof DashboardAccountGraphBranchesBranchSchemaSdlIndexRoute
  DashboardAccountGraphBranchesBranchSchemaSdlSubgraphSubgraphRoute: typeof DashboardAccountGraphBranchesBranchSchemaSdlSubgraphSubgraphRoute
}

const DashboardAccountGraphBranchesBranchSchemaSdlRouteChildren: DashboardAccountGraphBranchesBranchSchemaSdlRouteChildren =
  {
    DashboardAccountGraphBranchesBranchSchemaSdlFederatedSchemaRoute:
      DashboardAccountGraphBranchesBranchSchemaSdlFederatedSchemaRoute,
    DashboardAccountGraphBranchesBranchSchemaSdlIndexRoute:
      DashboardAccountGraphBranchesBranchSchemaSdlIndexRoute,
    DashboardAccountGraphBranchesBranchSchemaSdlSubgraphSubgraphRoute:
      DashboardAccountGraphBranchesBranchSchemaSdlSubgraphSubgraphRoute,
  }

const DashboardAccountGraphBranchesBranchSchemaSdlRouteWithChildren =
  DashboardAccountGraphBranchesBranchSchemaSdlRoute._addFileChildren(
    DashboardAccountGraphBranchesBranchSchemaSdlRouteChildren,
  )

interface DashboardAccountGraphBranchesBranchSchemaRouteChildren {
  DashboardAccountGraphBranchesBranchSchemaSdlRoute: typeof DashboardAccountGraphBranchesBranchSchemaSdlRouteWithChildren
  DashboardAccountGraphBranchesBranchSchemaIndexRoute: typeof DashboardAccountGraphBranchesBranchSchemaIndexRoute
}

const DashboardAccountGraphBranchesBranchSchemaRouteChildren: DashboardAccountGraphBranchesBranchSchemaRouteChildren =
  {
    DashboardAccountGraphBranchesBranchSchemaSdlRoute:
      DashboardAccountGraphBranchesBranchSchemaSdlRouteWithChildren,
    DashboardAccountGraphBranchesBranchSchemaIndexRoute:
      DashboardAccountGraphBranchesBranchSchemaIndexRoute,
  }

const DashboardAccountGraphBranchesBranchSchemaRouteWithChildren =
  DashboardAccountGraphBranchesBranchSchemaRoute._addFileChildren(
    DashboardAccountGraphBranchesBranchSchemaRouteChildren,
  )

interface DashboardAccountGraphBranchesBranchTracesRouteChildren {
  DashboardAccountGraphBranchesBranchTracesIdRoute: typeof DashboardAccountGraphBranchesBranchTracesIdRoute
}

const DashboardAccountGraphBranchesBranchTracesRouteChildren: DashboardAccountGraphBranchesBranchTracesRouteChildren =
  {
    DashboardAccountGraphBranchesBranchTracesIdRoute:
      DashboardAccountGraphBranchesBranchTracesIdRoute,
  }

const DashboardAccountGraphBranchesBranchTracesRouteWithChildren =
  DashboardAccountGraphBranchesBranchTracesRoute._addFileChildren(
    DashboardAccountGraphBranchesBranchTracesRouteChildren,
  )

interface DashboardAccountGraphBranchesBranchProposalsIdProposalRouteChildren {
  DashboardAccountGraphBranchesBranchProposalsIdProposalDiffRoute: typeof DashboardAccountGraphBranchesBranchProposalsIdProposalDiffRoute
  DashboardAccountGraphBranchesBranchProposalsIdProposalEditorRoute: typeof DashboardAccountGraphBranchesBranchProposalsIdProposalEditorRoute
  DashboardAccountGraphBranchesBranchProposalsIdProposalOverviewRoute: typeof DashboardAccountGraphBranchesBranchProposalsIdProposalOverviewRoute
  DashboardAccountGraphBranchesBranchProposalsIdProposalIndexRoute: typeof DashboardAccountGraphBranchesBranchProposalsIdProposalIndexRoute
}

const DashboardAccountGraphBranchesBranchProposalsIdProposalRouteChildren: DashboardAccountGraphBranchesBranchProposalsIdProposalRouteChildren =
  {
    DashboardAccountGraphBranchesBranchProposalsIdProposalDiffRoute:
      DashboardAccountGraphBranchesBranchProposalsIdProposalDiffRoute,
    DashboardAccountGraphBranchesBranchProposalsIdProposalEditorRoute:
      DashboardAccountGraphBranchesBranchProposalsIdProposalEditorRoute,
    DashboardAccountGraphBranchesBranchProposalsIdProposalOverviewRoute:
      DashboardAccountGraphBranchesBranchProposalsIdProposalOverviewRoute,
    DashboardAccountGraphBranchesBranchProposalsIdProposalIndexRoute:
      DashboardAccountGraphBranchesBranchProposalsIdProposalIndexRoute,
  }

const DashboardAccountGraphBranchesBranchProposalsIdProposalRouteWithChildren =
  DashboardAccountGraphBranchesBranchProposalsIdProposalRoute._addFileChildren(
    DashboardAccountGraphBranchesBranchProposalsIdProposalRouteChildren,
  )

interface DashboardAccountGraphBranchesBranchProposalsIdRouteChildren {
  DashboardAccountGraphBranchesBranchProposalsIdProposalRoute: typeof DashboardAccountGraphBranchesBranchProposalsIdProposalRouteWithChildren
}

const DashboardAccountGraphBranchesBranchProposalsIdRouteChildren: DashboardAccountGraphBranchesBranchProposalsIdRouteChildren =
  {
    DashboardAccountGraphBranchesBranchProposalsIdProposalRoute:
      DashboardAccountGraphBranchesBranchProposalsIdProposalRouteWithChildren,
  }

const DashboardAccountGraphBranchesBranchProposalsIdRouteWithChildren =
  DashboardAccountGraphBranchesBranchProposalsIdRoute._addFileChildren(
    DashboardAccountGraphBranchesBranchProposalsIdRouteChildren,
  )

interface DashboardAccountGraphBranchesBranchSettingsGraphChecksRouteChildren {
  DashboardAccountGraphBranchesBranchSettingsGraphChecksIndexRoute: typeof DashboardAccountGraphBranchesBranchSettingsGraphChecksIndexRoute
}

const DashboardAccountGraphBranchesBranchSettingsGraphChecksRouteChildren: DashboardAccountGraphBranchesBranchSettingsGraphChecksRouteChildren =
  {
    DashboardAccountGraphBranchesBranchSettingsGraphChecksIndexRoute:
      DashboardAccountGraphBranchesBranchSettingsGraphChecksIndexRoute,
  }

const DashboardAccountGraphBranchesBranchSettingsGraphChecksRouteWithChildren =
  DashboardAccountGraphBranchesBranchSettingsGraphChecksRoute._addFileChildren(
    DashboardAccountGraphBranchesBranchSettingsGraphChecksRouteChildren,
  )

interface DashboardAccountGraphBranchesBranchRouteChildren {
  DashboardAccountGraphBranchesBranchAnalyticsRoute: typeof DashboardAccountGraphBranchesBranchAnalyticsRoute
  DashboardAccountGraphBranchesBranchPathfinderRoute: typeof DashboardAccountGraphBranchesBranchPathfinderRoute
  DashboardAccountGraphBranchesBranchSchemaRoute: typeof DashboardAccountGraphBranchesBranchSchemaRouteWithChildren
  DashboardAccountGraphBranchesBranchSubgraphsRoute: typeof DashboardAccountGraphBranchesBranchSubgraphsRoute
  DashboardAccountGraphBranchesBranchTracesRoute: typeof DashboardAccountGraphBranchesBranchTracesRouteWithChildren
  DashboardAccountGraphBranchesBranchIndexRoute: typeof DashboardAccountGraphBranchesBranchIndexRoute
  DashboardAccountGraphBranchesBranchChangelogVersionRoute: typeof DashboardAccountGraphBranchesBranchChangelogVersionRoute
  DashboardAccountGraphBranchesBranchChecksIdRoute: typeof DashboardAccountGraphBranchesBranchChecksIdRoute
  DashboardAccountGraphBranchesBranchDeploymentsIdRoute: typeof DashboardAccountGraphBranchesBranchDeploymentsIdRoute
  DashboardAccountGraphBranchesBranchChangelogIndexRoute: typeof DashboardAccountGraphBranchesBranchChangelogIndexRoute
  DashboardAccountGraphBranchesBranchChecksIndexRoute: typeof DashboardAccountGraphBranchesBranchChecksIndexRoute
  DashboardAccountGraphBranchesBranchDeploymentsIndexRoute: typeof DashboardAccountGraphBranchesBranchDeploymentsIndexRoute
  DashboardAccountGraphBranchesBranchProposalsIndexRoute: typeof DashboardAccountGraphBranchesBranchProposalsIndexRoute
  DashboardAccountGraphBranchesBranchProposalsIdRoute: typeof DashboardAccountGraphBranchesBranchProposalsIdRouteWithChildren
  DashboardAccountGraphBranchesBranchSettingsBranchDangerZoneRoute: typeof DashboardAccountGraphBranchesBranchSettingsBranchDangerZoneRoute
  DashboardAccountGraphBranchesBranchSettingsBranchPathfinderRoute: typeof DashboardAccountGraphBranchesBranchSettingsBranchPathfinderRoute
  DashboardAccountGraphBranchesBranchSettingsBranchProposalsRoute: typeof DashboardAccountGraphBranchesBranchSettingsBranchProposalsRoute
  DashboardAccountGraphBranchesBranchSettingsGraphBranchesRoute: typeof DashboardAccountGraphBranchesBranchSettingsGraphBranchesRoute
  DashboardAccountGraphBranchesBranchSettingsGraphChecksRoute: typeof DashboardAccountGraphBranchesBranchSettingsGraphChecksRouteWithChildren
  DashboardAccountGraphBranchesBranchSettingsGraphDangerZoneRoute: typeof DashboardAccountGraphBranchesBranchSettingsGraphDangerZoneRoute
  DashboardAccountGraphBranchesBranchSettingsGraphIndexRoute: typeof DashboardAccountGraphBranchesBranchSettingsGraphIndexRoute
}

const DashboardAccountGraphBranchesBranchRouteChildren: DashboardAccountGraphBranchesBranchRouteChildren =
  {
    DashboardAccountGraphBranchesBranchAnalyticsRoute:
      DashboardAccountGraphBranchesBranchAnalyticsRoute,
    DashboardAccountGraphBranchesBranchPathfinderRoute:
      DashboardAccountGraphBranchesBranchPathfinderRoute,
    DashboardAccountGraphBranchesBranchSchemaRoute:
      DashboardAccountGraphBranchesBranchSchemaRouteWithChildren,
    DashboardAccountGraphBranchesBranchSubgraphsRoute:
      DashboardAccountGraphBranchesBranchSubgraphsRoute,
    DashboardAccountGraphBranchesBranchTracesRoute:
      DashboardAccountGraphBranchesBranchTracesRouteWithChildren,
    DashboardAccountGraphBranchesBranchIndexRoute:
      DashboardAccountGraphBranchesBranchIndexRoute,
    DashboardAccountGraphBranchesBranchChangelogVersionRoute:
      DashboardAccountGraphBranchesBranchChangelogVersionRoute,
    DashboardAccountGraphBranchesBranchChecksIdRoute:
      DashboardAccountGraphBranchesBranchChecksIdRoute,
    DashboardAccountGraphBranchesBranchDeploymentsIdRoute:
      DashboardAccountGraphBranchesBranchDeploymentsIdRoute,
    DashboardAccountGraphBranchesBranchChangelogIndexRoute:
      DashboardAccountGraphBranchesBranchChangelogIndexRoute,
    DashboardAccountGraphBranchesBranchChecksIndexRoute:
      DashboardAccountGraphBranchesBranchChecksIndexRoute,
    DashboardAccountGraphBranchesBranchDeploymentsIndexRoute:
      DashboardAccountGraphBranchesBranchDeploymentsIndexRoute,
    DashboardAccountGraphBranchesBranchProposalsIndexRoute:
      DashboardAccountGraphBranchesBranchProposalsIndexRoute,
    DashboardAccountGraphBranchesBranchProposalsIdRoute:
      DashboardAccountGraphBranchesBranchProposalsIdRouteWithChildren,
    DashboardAccountGraphBranchesBranchSettingsBranchDangerZoneRoute:
      DashboardAccountGraphBranchesBranchSettingsBranchDangerZoneRoute,
    DashboardAccountGraphBranchesBranchSettingsBranchPathfinderRoute:
      DashboardAccountGraphBranchesBranchSettingsBranchPathfinderRoute,
    DashboardAccountGraphBranchesBranchSettingsBranchProposalsRoute:
      DashboardAccountGraphBranchesBranchSettingsBranchProposalsRoute,
    DashboardAccountGraphBranchesBranchSettingsGraphBranchesRoute:
      DashboardAccountGraphBranchesBranchSettingsGraphBranchesRoute,
    DashboardAccountGraphBranchesBranchSettingsGraphChecksRoute:
      DashboardAccountGraphBranchesBranchSettingsGraphChecksRouteWithChildren,
    DashboardAccountGraphBranchesBranchSettingsGraphDangerZoneRoute:
      DashboardAccountGraphBranchesBranchSettingsGraphDangerZoneRoute,
    DashboardAccountGraphBranchesBranchSettingsGraphIndexRoute:
      DashboardAccountGraphBranchesBranchSettingsGraphIndexRoute,
  }

const DashboardAccountGraphBranchesBranchRouteWithChildren =
  DashboardAccountGraphBranchesBranchRoute._addFileChildren(
    DashboardAccountGraphBranchesBranchRouteChildren,
  )

interface DashboardAccountSettingsTeamsTeamTeamRouteChildren {
  DashboardAccountSettingsTeamsTeamTeamGraphsRoute: typeof DashboardAccountSettingsTeamsTeamTeamGraphsRoute
  DashboardAccountSettingsTeamsTeamTeamMembersRoute: typeof DashboardAccountSettingsTeamsTeamTeamMembersRoute
  DashboardAccountSettingsTeamsTeamTeamSettingsRoute: typeof DashboardAccountSettingsTeamsTeamTeamSettingsRoute
  DashboardAccountSettingsTeamsTeamTeamTeamsRoute: typeof DashboardAccountSettingsTeamsTeamTeamTeamsRoute
}

const DashboardAccountSettingsTeamsTeamTeamRouteChildren: DashboardAccountSettingsTeamsTeamTeamRouteChildren =
  {
    DashboardAccountSettingsTeamsTeamTeamGraphsRoute:
      DashboardAccountSettingsTeamsTeamTeamGraphsRoute,
    DashboardAccountSettingsTeamsTeamTeamMembersRoute:
      DashboardAccountSettingsTeamsTeamTeamMembersRoute,
    DashboardAccountSettingsTeamsTeamTeamSettingsRoute:
      DashboardAccountSettingsTeamsTeamTeamSettingsRoute,
    DashboardAccountSettingsTeamsTeamTeamTeamsRoute:
      DashboardAccountSettingsTeamsTeamTeamTeamsRoute,
  }

const DashboardAccountSettingsTeamsTeamTeamRouteWithChildren =
  DashboardAccountSettingsTeamsTeamTeamRoute._addFileChildren(
    DashboardAccountSettingsTeamsTeamTeamRouteChildren,
  )

interface DashboardAccountSettingsTeamsTeamRouteChildren {
  DashboardAccountSettingsTeamsTeamTeamRoute: typeof DashboardAccountSettingsTeamsTeamTeamRouteWithChildren
}

const DashboardAccountSettingsTeamsTeamRouteChildren: DashboardAccountSettingsTeamsTeamRouteChildren =
  {
    DashboardAccountSettingsTeamsTeamTeamRoute:
      DashboardAccountSettingsTeamsTeamTeamRouteWithChildren,
  }

const DashboardAccountSettingsTeamsTeamRouteWithChildren =
  DashboardAccountSettingsTeamsTeamRoute._addFileChildren(
    DashboardAccountSettingsTeamsTeamRouteChildren,
  )

interface DashboardAccountRouteChildren {
  DashboardAccountIndexRoute: typeof DashboardAccountIndexRoute
  DashboardAccountSettingsAccessTokensRoute: typeof DashboardAccountSettingsAccessTokensRoute
  DashboardAccountSettingsDangerZoneRoute: typeof DashboardAccountSettingsDangerZoneRoute
  DashboardAccountSettingsMembersRoute: typeof DashboardAccountSettingsMembersRoute
  DashboardAccountSettingsUsageRoute: typeof DashboardAccountSettingsUsageRoute
  DashboardAccountSettingsIndexRoute: typeof DashboardAccountSettingsIndexRoute
  DashboardAccountGraphBranchesBranchRoute: typeof DashboardAccountGraphBranchesBranchRouteWithChildren
  DashboardAccountSettingsTeamsIndexRoute: typeof DashboardAccountSettingsTeamsIndexRoute
  DashboardAccountSettingsTeamsTeamRoute: typeof DashboardAccountSettingsTeamsTeamRouteWithChildren
}

const DashboardAccountRouteChildren: DashboardAccountRouteChildren = {
  DashboardAccountIndexRoute: DashboardAccountIndexRoute,
  DashboardAccountSettingsAccessTokensRoute:
    DashboardAccountSettingsAccessTokensRoute,
  DashboardAccountSettingsDangerZoneRoute:
    DashboardAccountSettingsDangerZoneRoute,
  DashboardAccountSettingsMembersRoute: DashboardAccountSettingsMembersRoute,
  DashboardAccountSettingsUsageRoute: DashboardAccountSettingsUsageRoute,
  DashboardAccountSettingsIndexRoute: DashboardAccountSettingsIndexRoute,
  DashboardAccountGraphBranchesBranchRoute:
    DashboardAccountGraphBranchesBranchRouteWithChildren,
  DashboardAccountSettingsTeamsIndexRoute:
    DashboardAccountSettingsTeamsIndexRoute,
  DashboardAccountSettingsTeamsTeamRoute:
    DashboardAccountSettingsTeamsTeamRouteWithChildren,
}

const DashboardAccountRouteWithChildren =
  DashboardAccountRoute._addFileChildren(DashboardAccountRouteChildren)

interface DashboardPersonalRouteChildren {
  DashboardPersonalSettingsAccessTokensRoute: typeof DashboardPersonalSettingsAccessTokensRoute
  DashboardPersonalSettingsDangerZoneRoute: typeof DashboardPersonalSettingsDangerZoneRoute
  DashboardPersonalSettingsIndexRoute: typeof DashboardPersonalSettingsIndexRoute
}

const DashboardPersonalRouteChildren: DashboardPersonalRouteChildren = {
  DashboardPersonalSettingsAccessTokensRoute:
    DashboardPersonalSettingsAccessTokensRoute,
  DashboardPersonalSettingsDangerZoneRoute:
    DashboardPersonalSettingsDangerZoneRoute,
  DashboardPersonalSettingsIndexRoute: DashboardPersonalSettingsIndexRoute,
}

const DashboardPersonalRouteWithChildren =
  DashboardPersonalRoute._addFileChildren(DashboardPersonalRouteChildren)

interface DashboardRouteChildren {
  DashboardAccountRoute: typeof DashboardAccountRouteWithChildren
  DashboardPersonalRoute: typeof DashboardPersonalRouteWithChildren
  DashboardNewOrganizationRoute: typeof DashboardNewOrganizationRoute
  DashboardIndexRoute: typeof DashboardIndexRoute
  DashboardInvitesIdRoute: typeof DashboardInvitesIdRoute
}

const DashboardRouteChildren: DashboardRouteChildren = {
  DashboardAccountRoute: DashboardAccountRouteWithChildren,
  DashboardPersonalRoute: DashboardPersonalRouteWithChildren,
  DashboardNewOrganizationRoute: DashboardNewOrganizationRoute,
  DashboardIndexRoute: DashboardIndexRoute,
  DashboardInvitesIdRoute: DashboardInvitesIdRoute,
}

const DashboardRouteWithChildren = DashboardRoute._addFileChildren(
  DashboardRouteChildren,
)

export interface FileRoutesByFullPath {
  '': typeof DashboardPersonalRouteWithChildren
  '/admin': typeof AdminRoute
  '/auth-test-route': typeof AuthTestRouteRoute
  '/sign-in': typeof SignInRoute
  '/sign-up': typeof SignUpRoute
  '/$account': typeof DashboardAccountRouteWithChildren
  '/new-organization': typeof DashboardNewOrganizationRoute
  '/auth/cli': typeof AuthCliRoute
  '/auth/testing': typeof AuthTestingRoute
  '/': typeof DashboardIndexRoute
  '/invites/$id': typeof DashboardInvitesIdRoute
  '/$account/': typeof DashboardAccountIndexRoute
  '/$account/settings/access-tokens': typeof DashboardAccountSettingsAccessTokensRoute
  '/$account/settings/danger-zone': typeof DashboardAccountSettingsDangerZoneRoute
  '/$account/settings/members': typeof DashboardAccountSettingsMembersRoute
  '/$account/settings/usage': typeof DashboardAccountSettingsUsageRoute
  '/settings/access-tokens': typeof DashboardPersonalSettingsAccessTokensRoute
  '/settings/danger-zone': typeof DashboardPersonalSettingsDangerZoneRoute
  '/$account/settings': typeof DashboardAccountSettingsIndexRoute
  '/settings': typeof DashboardPersonalSettingsIndexRoute
  '/$account/$graph/branches/$branch': typeof DashboardAccountGraphBranchesBranchRouteWithChildren
  '/$account/settings/teams': typeof DashboardAccountSettingsTeamsIndexRoute
  '/$account/$graph/branches/$branch/analytics': typeof DashboardAccountGraphBranchesBranchAnalyticsRoute
  '/$account/$graph/branches/$branch/pathfinder': typeof DashboardAccountGraphBranchesBranchPathfinderRoute
  '/$account/$graph/branches/$branch/schema': typeof DashboardAccountGraphBranchesBranchSchemaRouteWithChildren
  '/$account/$graph/branches/$branch/subgraphs': typeof DashboardAccountGraphBranchesBranchSubgraphsRoute
  '/$account/$graph/branches/$branch/traces': typeof DashboardAccountGraphBranchesBranchTracesRouteWithChildren
  '/$account/settings/teams/$team': typeof DashboardAccountSettingsTeamsTeamTeamRouteWithChildren
  '/$account/$graph/branches/$branch/': typeof DashboardAccountGraphBranchesBranchIndexRoute
  '/$account/$graph/branches/$branch/changelog/$version': typeof DashboardAccountGraphBranchesBranchChangelogVersionRoute
  '/$account/$graph/branches/$branch/checks/$id': typeof DashboardAccountGraphBranchesBranchChecksIdRoute
  '/$account/$graph/branches/$branch/deployments/$id': typeof DashboardAccountGraphBranchesBranchDeploymentsIdRoute
  '/$account/$graph/branches/$branch/schema/sdl': typeof DashboardAccountGraphBranchesBranchSchemaSdlRouteWithChildren
  '/$account/$graph/branches/$branch/traces/$id': typeof DashboardAccountGraphBranchesBranchTracesIdRoute
  '/$account/settings/teams/$team/graphs': typeof DashboardAccountSettingsTeamsTeamTeamGraphsRoute
  '/$account/settings/teams/$team/members': typeof DashboardAccountSettingsTeamsTeamTeamMembersRoute
  '/$account/settings/teams/$team/settings': typeof DashboardAccountSettingsTeamsTeamTeamSettingsRoute
  '/$account/settings/teams/$team/teams': typeof DashboardAccountSettingsTeamsTeamTeamTeamsRoute
  '/$account/$graph/branches/$branch/changelog': typeof DashboardAccountGraphBranchesBranchChangelogIndexRoute
  '/$account/$graph/branches/$branch/checks': typeof DashboardAccountGraphBranchesBranchChecksIndexRoute
  '/$account/$graph/branches/$branch/deployments': typeof DashboardAccountGraphBranchesBranchDeploymentsIndexRoute
  '/$account/$graph/branches/$branch/proposals': typeof DashboardAccountGraphBranchesBranchProposalsIndexRoute
  '/$account/$graph/branches/$branch/schema/': typeof DashboardAccountGraphBranchesBranchSchemaIndexRoute
  '/$account/$graph/branches/$branch/proposals/$id': typeof DashboardAccountGraphBranchesBranchProposalsIdProposalRouteWithChildren
  '/$account/$graph/branches/$branch/schema/sdl/federated-schema': typeof DashboardAccountGraphBranchesBranchSchemaSdlFederatedSchemaRoute
  '/$account/$graph/branches/$branch/settings/branch/danger-zone': typeof DashboardAccountGraphBranchesBranchSettingsBranchDangerZoneRoute
  '/$account/$graph/branches/$branch/settings/branch/pathfinder': typeof DashboardAccountGraphBranchesBranchSettingsBranchPathfinderRoute
  '/$account/$graph/branches/$branch/settings/branch/proposals': typeof DashboardAccountGraphBranchesBranchSettingsBranchProposalsRoute
  '/$account/$graph/branches/$branch/settings/graph/branches': typeof DashboardAccountGraphBranchesBranchSettingsGraphBranchesRoute
  '/$account/$graph/branches/$branch/settings/graph/checks': typeof DashboardAccountGraphBranchesBranchSettingsGraphChecksRouteWithChildren
  '/$account/$graph/branches/$branch/settings/graph/danger-zone': typeof DashboardAccountGraphBranchesBranchSettingsGraphDangerZoneRoute
  '/$account/$graph/branches/$branch/schema/sdl/': typeof DashboardAccountGraphBranchesBranchSchemaSdlIndexRoute
  '/$account/$graph/branches/$branch/settings/graph': typeof DashboardAccountGraphBranchesBranchSettingsGraphIndexRoute
  '/$account/$graph/branches/$branch/proposals/$id/diff': typeof DashboardAccountGraphBranchesBranchProposalsIdProposalDiffRoute
  '/$account/$graph/branches/$branch/proposals/$id/editor': typeof DashboardAccountGraphBranchesBranchProposalsIdProposalEditorRoute
  '/$account/$graph/branches/$branch/proposals/$id/overview': typeof DashboardAccountGraphBranchesBranchProposalsIdProposalOverviewRoute
  '/$account/$graph/branches/$branch/schema/sdl/subgraph/$subgraph': typeof DashboardAccountGraphBranchesBranchSchemaSdlSubgraphSubgraphRoute
  '/$account/$graph/branches/$branch/proposals/$id/': typeof DashboardAccountGraphBranchesBranchProposalsIdProposalIndexRoute
  '/$account/$graph/branches/$branch/settings/graph/checks/': typeof DashboardAccountGraphBranchesBranchSettingsGraphChecksIndexRoute
}

export interface FileRoutesByTo {
  '/admin': typeof AdminRoute
  '/auth-test-route': typeof AuthTestRouteRoute
  '/sign-in': typeof SignInRoute
  '/sign-up': typeof SignUpRoute
  '': typeof DashboardPersonalRouteWithChildren
  '/new-organization': typeof DashboardNewOrganizationRoute
  '/auth/cli': typeof AuthCliRoute
  '/auth/testing': typeof AuthTestingRoute
  '/': typeof DashboardIndexRoute
  '/invites/$id': typeof DashboardInvitesIdRoute
  '/$account': typeof DashboardAccountIndexRoute
  '/$account/settings/access-tokens': typeof DashboardAccountSettingsAccessTokensRoute
  '/$account/settings/danger-zone': typeof DashboardAccountSettingsDangerZoneRoute
  '/$account/settings/members': typeof DashboardAccountSettingsMembersRoute
  '/$account/settings/usage': typeof DashboardAccountSettingsUsageRoute
  '/settings/access-tokens': typeof DashboardPersonalSettingsAccessTokensRoute
  '/settings/danger-zone': typeof DashboardPersonalSettingsDangerZoneRoute
  '/$account/settings': typeof DashboardAccountSettingsIndexRoute
  '/settings': typeof DashboardPersonalSettingsIndexRoute
  '/$account/settings/teams': typeof DashboardAccountSettingsTeamsIndexRoute
  '/$account/$graph/branches/$branch/analytics': typeof DashboardAccountGraphBranchesBranchAnalyticsRoute
  '/$account/$graph/branches/$branch/pathfinder': typeof DashboardAccountGraphBranchesBranchPathfinderRoute
  '/$account/$graph/branches/$branch/subgraphs': typeof DashboardAccountGraphBranchesBranchSubgraphsRoute
  '/$account/$graph/branches/$branch/traces': typeof DashboardAccountGraphBranchesBranchTracesRouteWithChildren
  '/$account/settings/teams/$team': typeof DashboardAccountSettingsTeamsTeamTeamRouteWithChildren
  '/$account/$graph/branches/$branch': typeof DashboardAccountGraphBranchesBranchIndexRoute
  '/$account/$graph/branches/$branch/changelog/$version': typeof DashboardAccountGraphBranchesBranchChangelogVersionRoute
  '/$account/$graph/branches/$branch/checks/$id': typeof DashboardAccountGraphBranchesBranchChecksIdRoute
  '/$account/$graph/branches/$branch/deployments/$id': typeof DashboardAccountGraphBranchesBranchDeploymentsIdRoute
  '/$account/$graph/branches/$branch/traces/$id': typeof DashboardAccountGraphBranchesBranchTracesIdRoute
  '/$account/settings/teams/$team/graphs': typeof DashboardAccountSettingsTeamsTeamTeamGraphsRoute
  '/$account/settings/teams/$team/members': typeof DashboardAccountSettingsTeamsTeamTeamMembersRoute
  '/$account/settings/teams/$team/settings': typeof DashboardAccountSettingsTeamsTeamTeamSettingsRoute
  '/$account/settings/teams/$team/teams': typeof DashboardAccountSettingsTeamsTeamTeamTeamsRoute
  '/$account/$graph/branches/$branch/changelog': typeof DashboardAccountGraphBranchesBranchChangelogIndexRoute
  '/$account/$graph/branches/$branch/checks': typeof DashboardAccountGraphBranchesBranchChecksIndexRoute
  '/$account/$graph/branches/$branch/deployments': typeof DashboardAccountGraphBranchesBranchDeploymentsIndexRoute
  '/$account/$graph/branches/$branch/proposals': typeof DashboardAccountGraphBranchesBranchProposalsIndexRoute
  '/$account/$graph/branches/$branch/schema': typeof DashboardAccountGraphBranchesBranchSchemaIndexRoute
  '/$account/$graph/branches/$branch/proposals/$id': typeof DashboardAccountGraphBranchesBranchProposalsIdProposalIndexRoute
  '/$account/$graph/branches/$branch/schema/sdl/federated-schema': typeof DashboardAccountGraphBranchesBranchSchemaSdlFederatedSchemaRoute
  '/$account/$graph/branches/$branch/settings/branch/danger-zone': typeof DashboardAccountGraphBranchesBranchSettingsBranchDangerZoneRoute
  '/$account/$graph/branches/$branch/settings/branch/pathfinder': typeof DashboardAccountGraphBranchesBranchSettingsBranchPathfinderRoute
  '/$account/$graph/branches/$branch/settings/branch/proposals': typeof DashboardAccountGraphBranchesBranchSettingsBranchProposalsRoute
  '/$account/$graph/branches/$branch/settings/graph/branches': typeof DashboardAccountGraphBranchesBranchSettingsGraphBranchesRoute
  '/$account/$graph/branches/$branch/settings/graph/danger-zone': typeof DashboardAccountGraphBranchesBranchSettingsGraphDangerZoneRoute
  '/$account/$graph/branches/$branch/schema/sdl': typeof DashboardAccountGraphBranchesBranchSchemaSdlIndexRoute
  '/$account/$graph/branches/$branch/settings/graph': typeof DashboardAccountGraphBranchesBranchSettingsGraphIndexRoute
  '/$account/$graph/branches/$branch/proposals/$id/diff': typeof DashboardAccountGraphBranchesBranchProposalsIdProposalDiffRoute
  '/$account/$graph/branches/$branch/proposals/$id/editor': typeof DashboardAccountGraphBranchesBranchProposalsIdProposalEditorRoute
  '/$account/$graph/branches/$branch/proposals/$id/overview': typeof DashboardAccountGraphBranchesBranchProposalsIdProposalOverviewRoute
  '/$account/$graph/branches/$branch/schema/sdl/subgraph/$subgraph': typeof DashboardAccountGraphBranchesBranchSchemaSdlSubgraphSubgraphRoute
  '/$account/$graph/branches/$branch/settings/graph/checks': typeof DashboardAccountGraphBranchesBranchSettingsGraphChecksIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_dashboard': typeof DashboardRouteWithChildren
  '/admin': typeof AdminRoute
  '/auth-test-route': typeof AuthTestRouteRoute
  '/sign-in': typeof SignInRoute
  '/sign-up': typeof SignUpRoute
  '/_dashboard/$account': typeof DashboardAccountRouteWithChildren
  '/_dashboard/_personal': typeof DashboardPersonalRouteWithChildren
  '/_dashboard/new-organization': typeof DashboardNewOrganizationRoute
  '/auth/cli': typeof AuthCliRoute
  '/auth/testing': typeof AuthTestingRoute
  '/_dashboard/': typeof DashboardIndexRoute
  '/_dashboard/invites/$id': typeof DashboardInvitesIdRoute
  '/_dashboard/$account/': typeof DashboardAccountIndexRoute
  '/_dashboard/$account/settings/access-tokens': typeof DashboardAccountSettingsAccessTokensRoute
  '/_dashboard/$account/settings/danger-zone': typeof DashboardAccountSettingsDangerZoneRoute
  '/_dashboard/$account/settings/members': typeof DashboardAccountSettingsMembersRoute
  '/_dashboard/$account/settings/usage': typeof DashboardAccountSettingsUsageRoute
  '/_dashboard/_personal/settings/access-tokens': typeof DashboardPersonalSettingsAccessTokensRoute
  '/_dashboard/_personal/settings/danger-zone': typeof DashboardPersonalSettingsDangerZoneRoute
  '/_dashboard/$account/settings/': typeof DashboardAccountSettingsIndexRoute
  '/_dashboard/_personal/settings/': typeof DashboardPersonalSettingsIndexRoute
  '/_dashboard/$account/$graph/branches/$branch': typeof DashboardAccountGraphBranchesBranchRouteWithChildren
  '/_dashboard/$account/settings/teams/': typeof DashboardAccountSettingsTeamsIndexRoute
  '/_dashboard/$account/$graph/branches/$branch/analytics': typeof DashboardAccountGraphBranchesBranchAnalyticsRoute
  '/_dashboard/$account/$graph/branches/$branch/pathfinder': typeof DashboardAccountGraphBranchesBranchPathfinderRoute
  '/_dashboard/$account/$graph/branches/$branch/schema': typeof DashboardAccountGraphBranchesBranchSchemaRouteWithChildren
  '/_dashboard/$account/$graph/branches/$branch/subgraphs': typeof DashboardAccountGraphBranchesBranchSubgraphsRoute
  '/_dashboard/$account/$graph/branches/$branch/traces': typeof DashboardAccountGraphBranchesBranchTracesRouteWithChildren
  '/_dashboard/$account/settings/teams/$team': typeof DashboardAccountSettingsTeamsTeamRouteWithChildren
  '/_dashboard/$account/settings/teams/$team/_team': typeof DashboardAccountSettingsTeamsTeamTeamRouteWithChildren
  '/_dashboard/$account/$graph/branches/$branch/': typeof DashboardAccountGraphBranchesBranchIndexRoute
  '/_dashboard/$account/$graph/branches/$branch/changelog/$version': typeof DashboardAccountGraphBranchesBranchChangelogVersionRoute
  '/_dashboard/$account/$graph/branches/$branch/checks/$id': typeof DashboardAccountGraphBranchesBranchChecksIdRoute
  '/_dashboard/$account/$graph/branches/$branch/deployments/$id': typeof DashboardAccountGraphBranchesBranchDeploymentsIdRoute
  '/_dashboard/$account/$graph/branches/$branch/schema/sdl': typeof DashboardAccountGraphBranchesBranchSchemaSdlRouteWithChildren
  '/_dashboard/$account/$graph/branches/$branch/traces/$id': typeof DashboardAccountGraphBranchesBranchTracesIdRoute
  '/_dashboard/$account/settings/teams/$team/_team/graphs': typeof DashboardAccountSettingsTeamsTeamTeamGraphsRoute
  '/_dashboard/$account/settings/teams/$team/_team/members': typeof DashboardAccountSettingsTeamsTeamTeamMembersRoute
  '/_dashboard/$account/settings/teams/$team/_team/settings': typeof DashboardAccountSettingsTeamsTeamTeamSettingsRoute
  '/_dashboard/$account/settings/teams/$team/_team/teams': typeof DashboardAccountSettingsTeamsTeamTeamTeamsRoute
  '/_dashboard/$account/$graph/branches/$branch/changelog/': typeof DashboardAccountGraphBranchesBranchChangelogIndexRoute
  '/_dashboard/$account/$graph/branches/$branch/checks/': typeof DashboardAccountGraphBranchesBranchChecksIndexRoute
  '/_dashboard/$account/$graph/branches/$branch/deployments/': typeof DashboardAccountGraphBranchesBranchDeploymentsIndexRoute
  '/_dashboard/$account/$graph/branches/$branch/proposals/': typeof DashboardAccountGraphBranchesBranchProposalsIndexRoute
  '/_dashboard/$account/$graph/branches/$branch/schema/': typeof DashboardAccountGraphBranchesBranchSchemaIndexRoute
  '/_dashboard/$account/$graph/branches/$branch/proposals/$id': typeof DashboardAccountGraphBranchesBranchProposalsIdRouteWithChildren
  '/_dashboard/$account/$graph/branches/$branch/proposals/$id/_proposal': typeof DashboardAccountGraphBranchesBranchProposalsIdProposalRouteWithChildren
  '/_dashboard/$account/$graph/branches/$branch/schema/sdl/federated-schema': typeof DashboardAccountGraphBranchesBranchSchemaSdlFederatedSchemaRoute
  '/_dashboard/$account/$graph/branches/$branch/settings/branch/danger-zone': typeof DashboardAccountGraphBranchesBranchSettingsBranchDangerZoneRoute
  '/_dashboard/$account/$graph/branches/$branch/settings/branch/pathfinder': typeof DashboardAccountGraphBranchesBranchSettingsBranchPathfinderRoute
  '/_dashboard/$account/$graph/branches/$branch/settings/branch/proposals': typeof DashboardAccountGraphBranchesBranchSettingsBranchProposalsRoute
  '/_dashboard/$account/$graph/branches/$branch/settings/graph/branches': typeof DashboardAccountGraphBranchesBranchSettingsGraphBranchesRoute
  '/_dashboard/$account/$graph/branches/$branch/settings/graph/checks': typeof DashboardAccountGraphBranchesBranchSettingsGraphChecksRouteWithChildren
  '/_dashboard/$account/$graph/branches/$branch/settings/graph/danger-zone': typeof DashboardAccountGraphBranchesBranchSettingsGraphDangerZoneRoute
  '/_dashboard/$account/$graph/branches/$branch/schema/sdl/': typeof DashboardAccountGraphBranchesBranchSchemaSdlIndexRoute
  '/_dashboard/$account/$graph/branches/$branch/settings/graph/': typeof DashboardAccountGraphBranchesBranchSettingsGraphIndexRoute
  '/_dashboard/$account/$graph/branches/$branch/proposals/$id/_proposal/diff': typeof DashboardAccountGraphBranchesBranchProposalsIdProposalDiffRoute
  '/_dashboard/$account/$graph/branches/$branch/proposals/$id/_proposal/editor': typeof DashboardAccountGraphBranchesBranchProposalsIdProposalEditorRoute
  '/_dashboard/$account/$graph/branches/$branch/proposals/$id/_proposal/overview': typeof DashboardAccountGraphBranchesBranchProposalsIdProposalOverviewRoute
  '/_dashboard/$account/$graph/branches/$branch/schema/sdl/subgraph/$subgraph': typeof DashboardAccountGraphBranchesBranchSchemaSdlSubgraphSubgraphRoute
  '/_dashboard/$account/$graph/branches/$branch/proposals/$id/_proposal/': typeof DashboardAccountGraphBranchesBranchProposalsIdProposalIndexRoute
  '/_dashboard/$account/$graph/branches/$branch/settings/graph/checks/': typeof DashboardAccountGraphBranchesBranchSettingsGraphChecksIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/admin'
    | '/auth-test-route'
    | '/sign-in'
    | '/sign-up'
    | '/$account'
    | '/new-organization'
    | '/auth/cli'
    | '/auth/testing'
    | '/'
    | '/invites/$id'
    | '/$account/'
    | '/$account/settings/access-tokens'
    | '/$account/settings/danger-zone'
    | '/$account/settings/members'
    | '/$account/settings/usage'
    | '/settings/access-tokens'
    | '/settings/danger-zone'
    | '/$account/settings'
    | '/settings'
    | '/$account/$graph/branches/$branch'
    | '/$account/settings/teams'
    | '/$account/$graph/branches/$branch/analytics'
    | '/$account/$graph/branches/$branch/pathfinder'
    | '/$account/$graph/branches/$branch/schema'
    | '/$account/$graph/branches/$branch/subgraphs'
    | '/$account/$graph/branches/$branch/traces'
    | '/$account/settings/teams/$team'
    | '/$account/$graph/branches/$branch/'
    | '/$account/$graph/branches/$branch/changelog/$version'
    | '/$account/$graph/branches/$branch/checks/$id'
    | '/$account/$graph/branches/$branch/deployments/$id'
    | '/$account/$graph/branches/$branch/schema/sdl'
    | '/$account/$graph/branches/$branch/traces/$id'
    | '/$account/settings/teams/$team/graphs'
    | '/$account/settings/teams/$team/members'
    | '/$account/settings/teams/$team/settings'
    | '/$account/settings/teams/$team/teams'
    | '/$account/$graph/branches/$branch/changelog'
    | '/$account/$graph/branches/$branch/checks'
    | '/$account/$graph/branches/$branch/deployments'
    | '/$account/$graph/branches/$branch/proposals'
    | '/$account/$graph/branches/$branch/schema/'
    | '/$account/$graph/branches/$branch/proposals/$id'
    | '/$account/$graph/branches/$branch/schema/sdl/federated-schema'
    | '/$account/$graph/branches/$branch/settings/branch/danger-zone'
    | '/$account/$graph/branches/$branch/settings/branch/pathfinder'
    | '/$account/$graph/branches/$branch/settings/branch/proposals'
    | '/$account/$graph/branches/$branch/settings/graph/branches'
    | '/$account/$graph/branches/$branch/settings/graph/checks'
    | '/$account/$graph/branches/$branch/settings/graph/danger-zone'
    | '/$account/$graph/branches/$branch/schema/sdl/'
    | '/$account/$graph/branches/$branch/settings/graph'
    | '/$account/$graph/branches/$branch/proposals/$id/diff'
    | '/$account/$graph/branches/$branch/proposals/$id/editor'
    | '/$account/$graph/branches/$branch/proposals/$id/overview'
    | '/$account/$graph/branches/$branch/schema/sdl/subgraph/$subgraph'
    | '/$account/$graph/branches/$branch/proposals/$id/'
    | '/$account/$graph/branches/$branch/settings/graph/checks/'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/admin'
    | '/auth-test-route'
    | '/sign-in'
    | '/sign-up'
    | ''
    | '/new-organization'
    | '/auth/cli'
    | '/auth/testing'
    | '/'
    | '/invites/$id'
    | '/$account'
    | '/$account/settings/access-tokens'
    | '/$account/settings/danger-zone'
    | '/$account/settings/members'
    | '/$account/settings/usage'
    | '/settings/access-tokens'
    | '/settings/danger-zone'
    | '/$account/settings'
    | '/settings'
    | '/$account/settings/teams'
    | '/$account/$graph/branches/$branch/analytics'
    | '/$account/$graph/branches/$branch/pathfinder'
    | '/$account/$graph/branches/$branch/subgraphs'
    | '/$account/$graph/branches/$branch/traces'
    | '/$account/settings/teams/$team'
    | '/$account/$graph/branches/$branch'
    | '/$account/$graph/branches/$branch/changelog/$version'
    | '/$account/$graph/branches/$branch/checks/$id'
    | '/$account/$graph/branches/$branch/deployments/$id'
    | '/$account/$graph/branches/$branch/traces/$id'
    | '/$account/settings/teams/$team/graphs'
    | '/$account/settings/teams/$team/members'
    | '/$account/settings/teams/$team/settings'
    | '/$account/settings/teams/$team/teams'
    | '/$account/$graph/branches/$branch/changelog'
    | '/$account/$graph/branches/$branch/checks'
    | '/$account/$graph/branches/$branch/deployments'
    | '/$account/$graph/branches/$branch/proposals'
    | '/$account/$graph/branches/$branch/schema'
    | '/$account/$graph/branches/$branch/proposals/$id'
    | '/$account/$graph/branches/$branch/schema/sdl/federated-schema'
    | '/$account/$graph/branches/$branch/settings/branch/danger-zone'
    | '/$account/$graph/branches/$branch/settings/branch/pathfinder'
    | '/$account/$graph/branches/$branch/settings/branch/proposals'
    | '/$account/$graph/branches/$branch/settings/graph/branches'
    | '/$account/$graph/branches/$branch/settings/graph/danger-zone'
    | '/$account/$graph/branches/$branch/schema/sdl'
    | '/$account/$graph/branches/$branch/settings/graph'
    | '/$account/$graph/branches/$branch/proposals/$id/diff'
    | '/$account/$graph/branches/$branch/proposals/$id/editor'
    | '/$account/$graph/branches/$branch/proposals/$id/overview'
    | '/$account/$graph/branches/$branch/schema/sdl/subgraph/$subgraph'
    | '/$account/$graph/branches/$branch/settings/graph/checks'
  id:
    | '__root__'
    | '/_dashboard'
    | '/admin'
    | '/auth-test-route'
    | '/sign-in'
    | '/sign-up'
    | '/_dashboard/$account'
    | '/_dashboard/_personal'
    | '/_dashboard/new-organization'
    | '/auth/cli'
    | '/auth/testing'
    | '/_dashboard/'
    | '/_dashboard/invites/$id'
    | '/_dashboard/$account/'
    | '/_dashboard/$account/settings/access-tokens'
    | '/_dashboard/$account/settings/danger-zone'
    | '/_dashboard/$account/settings/members'
    | '/_dashboard/$account/settings/usage'
    | '/_dashboard/_personal/settings/access-tokens'
    | '/_dashboard/_personal/settings/danger-zone'
    | '/_dashboard/$account/settings/'
    | '/_dashboard/_personal/settings/'
    | '/_dashboard/$account/$graph/branches/$branch'
    | '/_dashboard/$account/settings/teams/'
    | '/_dashboard/$account/$graph/branches/$branch/analytics'
    | '/_dashboard/$account/$graph/branches/$branch/pathfinder'
    | '/_dashboard/$account/$graph/branches/$branch/schema'
    | '/_dashboard/$account/$graph/branches/$branch/subgraphs'
    | '/_dashboard/$account/$graph/branches/$branch/traces'
    | '/_dashboard/$account/settings/teams/$team'
    | '/_dashboard/$account/settings/teams/$team/_team'
    | '/_dashboard/$account/$graph/branches/$branch/'
    | '/_dashboard/$account/$graph/branches/$branch/changelog/$version'
    | '/_dashboard/$account/$graph/branches/$branch/checks/$id'
    | '/_dashboard/$account/$graph/branches/$branch/deployments/$id'
    | '/_dashboard/$account/$graph/branches/$branch/schema/sdl'
    | '/_dashboard/$account/$graph/branches/$branch/traces/$id'
    | '/_dashboard/$account/settings/teams/$team/_team/graphs'
    | '/_dashboard/$account/settings/teams/$team/_team/members'
    | '/_dashboard/$account/settings/teams/$team/_team/settings'
    | '/_dashboard/$account/settings/teams/$team/_team/teams'
    | '/_dashboard/$account/$graph/branches/$branch/changelog/'
    | '/_dashboard/$account/$graph/branches/$branch/checks/'
    | '/_dashboard/$account/$graph/branches/$branch/deployments/'
    | '/_dashboard/$account/$graph/branches/$branch/proposals/'
    | '/_dashboard/$account/$graph/branches/$branch/schema/'
    | '/_dashboard/$account/$graph/branches/$branch/proposals/$id'
    | '/_dashboard/$account/$graph/branches/$branch/proposals/$id/_proposal'
    | '/_dashboard/$account/$graph/branches/$branch/schema/sdl/federated-schema'
    | '/_dashboard/$account/$graph/branches/$branch/settings/branch/danger-zone'
    | '/_dashboard/$account/$graph/branches/$branch/settings/branch/pathfinder'
    | '/_dashboard/$account/$graph/branches/$branch/settings/branch/proposals'
    | '/_dashboard/$account/$graph/branches/$branch/settings/graph/branches'
    | '/_dashboard/$account/$graph/branches/$branch/settings/graph/checks'
    | '/_dashboard/$account/$graph/branches/$branch/settings/graph/danger-zone'
    | '/_dashboard/$account/$graph/branches/$branch/schema/sdl/'
    | '/_dashboard/$account/$graph/branches/$branch/settings/graph/'
    | '/_dashboard/$account/$graph/branches/$branch/proposals/$id/_proposal/diff'
    | '/_dashboard/$account/$graph/branches/$branch/proposals/$id/_proposal/editor'
    | '/_dashboard/$account/$graph/branches/$branch/proposals/$id/_proposal/overview'
    | '/_dashboard/$account/$graph/branches/$branch/schema/sdl/subgraph/$subgraph'
    | '/_dashboard/$account/$graph/branches/$branch/proposals/$id/_proposal/'
    | '/_dashboard/$account/$graph/branches/$branch/settings/graph/checks/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  DashboardRoute: typeof DashboardRouteWithChildren
  AdminRoute: typeof AdminRoute
  AuthTestRouteRoute: typeof AuthTestRouteRoute
  SignInRoute: typeof SignInRoute
  SignUpRoute: typeof SignUpRoute
  AuthCliRoute: typeof AuthCliRoute
  AuthTestingRoute: typeof AuthTestingRoute
}

const rootRouteChildren: RootRouteChildren = {
  DashboardRoute: DashboardRouteWithChildren,
  AdminRoute: AdminRoute,
  AuthTestRouteRoute: AuthTestRouteRoute,
  SignInRoute: SignInRoute,
  SignUpRoute: SignUpRoute,
  AuthCliRoute: AuthCliRoute,
  AuthTestingRoute: AuthTestingRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_dashboard",
        "/admin",
        "/auth-test-route",
        "/sign-in",
        "/sign-up",
        "/auth/cli",
        "/auth/testing"
      ]
    },
    "/_dashboard": {
      "filePath": "_dashboard.tsx",
      "children": [
        "/_dashboard/$account",
        "/_dashboard/_personal",
        "/_dashboard/new-organization",
        "/_dashboard/",
        "/_dashboard/invites/$id"
      ]
    },
    "/admin": {
      "filePath": "admin.tsx"
    },
    "/auth-test-route": {
      "filePath": "auth-test-route.tsx"
    },
    "/sign-in": {
      "filePath": "sign-in.tsx"
    },
    "/sign-up": {
      "filePath": "sign-up.tsx"
    },
    "/_dashboard/$account": {
      "filePath": "_dashboard.$account.tsx",
      "parent": "/_dashboard",
      "children": [
        "/_dashboard/$account/",
        "/_dashboard/$account/settings/access-tokens",
        "/_dashboard/$account/settings/danger-zone",
        "/_dashboard/$account/settings/members",
        "/_dashboard/$account/settings/usage",
        "/_dashboard/$account/settings/",
        "/_dashboard/$account/$graph/branches/$branch",
        "/_dashboard/$account/settings/teams/",
        "/_dashboard/$account/settings/teams/$team"
      ]
    },
    "/_dashboard/_personal": {
      "filePath": "_dashboard._personal.tsx",
      "parent": "/_dashboard",
      "children": [
        "/_dashboard/_personal/settings/access-tokens",
        "/_dashboard/_personal/settings/danger-zone",
        "/_dashboard/_personal/settings/"
      ]
    },
    "/_dashboard/new-organization": {
      "filePath": "_dashboard.new-organization.tsx",
      "parent": "/_dashboard"
    },
    "/auth/cli": {
      "filePath": "auth.cli.tsx"
    },
    "/auth/testing": {
      "filePath": "auth.testing.tsx"
    },
    "/_dashboard/": {
      "filePath": "_dashboard.index.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/invites/$id": {
      "filePath": "_dashboard.invites.$id.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/$account/": {
      "filePath": "_dashboard.$account.index.tsx",
      "parent": "/_dashboard/$account"
    },
    "/_dashboard/$account/settings/access-tokens": {
      "filePath": "_dashboard.$account.settings.access-tokens.tsx",
      "parent": "/_dashboard/$account"
    },
    "/_dashboard/$account/settings/danger-zone": {
      "filePath": "_dashboard.$account.settings.danger-zone.tsx",
      "parent": "/_dashboard/$account"
    },
    "/_dashboard/$account/settings/members": {
      "filePath": "_dashboard.$account.settings.members.tsx",
      "parent": "/_dashboard/$account"
    },
    "/_dashboard/$account/settings/usage": {
      "filePath": "_dashboard.$account.settings.usage.tsx",
      "parent": "/_dashboard/$account"
    },
    "/_dashboard/_personal/settings/access-tokens": {
      "filePath": "_dashboard._personal.settings.access-tokens.tsx",
      "parent": "/_dashboard/_personal"
    },
    "/_dashboard/_personal/settings/danger-zone": {
      "filePath": "_dashboard._personal.settings.danger-zone.tsx",
      "parent": "/_dashboard/_personal"
    },
    "/_dashboard/$account/settings/": {
      "filePath": "_dashboard.$account.settings.index.tsx",
      "parent": "/_dashboard/$account"
    },
    "/_dashboard/_personal/settings/": {
      "filePath": "_dashboard._personal.settings.index.tsx",
      "parent": "/_dashboard/_personal"
    },
    "/_dashboard/$account/$graph/branches/$branch": {
      "filePath": "_dashboard.$account.$graph.branches.$branch.tsx",
      "parent": "/_dashboard/$account",
      "children": [
        "/_dashboard/$account/$graph/branches/$branch/analytics",
        "/_dashboard/$account/$graph/branches/$branch/pathfinder",
        "/_dashboard/$account/$graph/branches/$branch/schema",
        "/_dashboard/$account/$graph/branches/$branch/subgraphs",
        "/_dashboard/$account/$graph/branches/$branch/traces",
        "/_dashboard/$account/$graph/branches/$branch/",
        "/_dashboard/$account/$graph/branches/$branch/changelog/$version",
        "/_dashboard/$account/$graph/branches/$branch/checks/$id",
        "/_dashboard/$account/$graph/branches/$branch/deployments/$id",
        "/_dashboard/$account/$graph/branches/$branch/changelog/",
        "/_dashboard/$account/$graph/branches/$branch/checks/",
        "/_dashboard/$account/$graph/branches/$branch/deployments/",
        "/_dashboard/$account/$graph/branches/$branch/proposals/",
        "/_dashboard/$account/$graph/branches/$branch/proposals/$id",
        "/_dashboard/$account/$graph/branches/$branch/settings/branch/danger-zone",
        "/_dashboard/$account/$graph/branches/$branch/settings/branch/pathfinder",
        "/_dashboard/$account/$graph/branches/$branch/settings/branch/proposals",
        "/_dashboard/$account/$graph/branches/$branch/settings/graph/branches",
        "/_dashboard/$account/$graph/branches/$branch/settings/graph/checks",
        "/_dashboard/$account/$graph/branches/$branch/settings/graph/danger-zone",
        "/_dashboard/$account/$graph/branches/$branch/settings/graph/"
      ]
    },
    "/_dashboard/$account/settings/teams/": {
      "filePath": "_dashboard.$account.settings.teams.index.tsx",
      "parent": "/_dashboard/$account"
    },
    "/_dashboard/$account/$graph/branches/$branch/analytics": {
      "filePath": "_dashboard.$account.$graph.branches.$branch.analytics.tsx",
      "parent": "/_dashboard/$account/$graph/branches/$branch"
    },
    "/_dashboard/$account/$graph/branches/$branch/pathfinder": {
      "filePath": "_dashboard.$account.$graph.branches.$branch.pathfinder.tsx",
      "parent": "/_dashboard/$account/$graph/branches/$branch"
    },
    "/_dashboard/$account/$graph/branches/$branch/schema": {
      "filePath": "_dashboard.$account.$graph.branches.$branch.schema.tsx",
      "parent": "/_dashboard/$account/$graph/branches/$branch",
      "children": [
        "/_dashboard/$account/$graph/branches/$branch/schema/sdl",
        "/_dashboard/$account/$graph/branches/$branch/schema/"
      ]
    },
    "/_dashboard/$account/$graph/branches/$branch/subgraphs": {
      "filePath": "_dashboard.$account.$graph.branches.$branch.subgraphs.tsx",
      "parent": "/_dashboard/$account/$graph/branches/$branch"
    },
    "/_dashboard/$account/$graph/branches/$branch/traces": {
      "filePath": "_dashboard.$account.$graph.branches.$branch.traces.tsx",
      "parent": "/_dashboard/$account/$graph/branches/$branch",
      "children": [
        "/_dashboard/$account/$graph/branches/$branch/traces/$id"
      ]
    },
    "/_dashboard/$account/settings/teams/$team": {
      "filePath": "/",
      "parent": "/_dashboard/$account",
      "children": [
        "/_dashboard/$account/settings/teams/$team/_team"
      ]
    },
    "/_dashboard/$account/settings/teams/$team/_team": {
      "filePath": "_dashboard.$account.settings.teams.$team._team.tsx",
      "parent": "/_dashboard/$account/settings/teams/$team",
      "children": [
        "/_dashboard/$account/settings/teams/$team/_team/graphs",
        "/_dashboard/$account/settings/teams/$team/_team/members",
        "/_dashboard/$account/settings/teams/$team/_team/settings",
        "/_dashboard/$account/settings/teams/$team/_team/teams"
      ]
    },
    "/_dashboard/$account/$graph/branches/$branch/": {
      "filePath": "_dashboard.$account.$graph.branches.$branch.index.tsx",
      "parent": "/_dashboard/$account/$graph/branches/$branch"
    },
    "/_dashboard/$account/$graph/branches/$branch/changelog/$version": {
      "filePath": "_dashboard.$account.$graph.branches.$branch.changelog.$version.tsx",
      "parent": "/_dashboard/$account/$graph/branches/$branch"
    },
    "/_dashboard/$account/$graph/branches/$branch/checks/$id": {
      "filePath": "_dashboard.$account.$graph.branches.$branch.checks.$id.tsx",
      "parent": "/_dashboard/$account/$graph/branches/$branch"
    },
    "/_dashboard/$account/$graph/branches/$branch/deployments/$id": {
      "filePath": "_dashboard.$account.$graph.branches.$branch.deployments.$id.tsx",
      "parent": "/_dashboard/$account/$graph/branches/$branch"
    },
    "/_dashboard/$account/$graph/branches/$branch/schema/sdl": {
      "filePath": "_dashboard.$account.$graph.branches.$branch.schema.sdl.tsx",
      "parent": "/_dashboard/$account/$graph/branches/$branch/schema",
      "children": [
        "/_dashboard/$account/$graph/branches/$branch/schema/sdl/federated-schema",
        "/_dashboard/$account/$graph/branches/$branch/schema/sdl/",
        "/_dashboard/$account/$graph/branches/$branch/schema/sdl/subgraph/$subgraph"
      ]
    },
    "/_dashboard/$account/$graph/branches/$branch/traces/$id": {
      "filePath": "_dashboard.$account.$graph.branches.$branch.traces.$id.tsx",
      "parent": "/_dashboard/$account/$graph/branches/$branch/traces"
    },
    "/_dashboard/$account/settings/teams/$team/_team/graphs": {
      "filePath": "_dashboard.$account.settings.teams.$team._team.graphs.tsx",
      "parent": "/_dashboard/$account/settings/teams/$team/_team"
    },
    "/_dashboard/$account/settings/teams/$team/_team/members": {
      "filePath": "_dashboard.$account.settings.teams.$team._team.members.tsx",
      "parent": "/_dashboard/$account/settings/teams/$team/_team"
    },
    "/_dashboard/$account/settings/teams/$team/_team/settings": {
      "filePath": "_dashboard.$account.settings.teams.$team._team.settings.tsx",
      "parent": "/_dashboard/$account/settings/teams/$team/_team"
    },
    "/_dashboard/$account/settings/teams/$team/_team/teams": {
      "filePath": "_dashboard.$account.settings.teams.$team._team.teams.tsx",
      "parent": "/_dashboard/$account/settings/teams/$team/_team"
    },
    "/_dashboard/$account/$graph/branches/$branch/changelog/": {
      "filePath": "_dashboard.$account.$graph.branches.$branch.changelog.index.tsx",
      "parent": "/_dashboard/$account/$graph/branches/$branch"
    },
    "/_dashboard/$account/$graph/branches/$branch/checks/": {
      "filePath": "_dashboard.$account.$graph.branches.$branch.checks.index.tsx",
      "parent": "/_dashboard/$account/$graph/branches/$branch"
    },
    "/_dashboard/$account/$graph/branches/$branch/deployments/": {
      "filePath": "_dashboard.$account.$graph.branches.$branch.deployments.index.tsx",
      "parent": "/_dashboard/$account/$graph/branches/$branch"
    },
    "/_dashboard/$account/$graph/branches/$branch/proposals/": {
      "filePath": "_dashboard.$account.$graph.branches.$branch.proposals.index.tsx",
      "parent": "/_dashboard/$account/$graph/branches/$branch"
    },
    "/_dashboard/$account/$graph/branches/$branch/schema/": {
      "filePath": "_dashboard.$account.$graph.branches.$branch.schema.index.tsx",
      "parent": "/_dashboard/$account/$graph/branches/$branch/schema"
    },
    "/_dashboard/$account/$graph/branches/$branch/proposals/$id": {
      "filePath": "/",
      "parent": "/_dashboard/$account/$graph/branches/$branch",
      "children": [
        "/_dashboard/$account/$graph/branches/$branch/proposals/$id/_proposal"
      ]
    },
    "/_dashboard/$account/$graph/branches/$branch/proposals/$id/_proposal": {
      "filePath": "_dashboard.$account.$graph.branches.$branch.proposals.$id._proposal.tsx",
      "parent": "/_dashboard/$account/$graph/branches/$branch/proposals/$id",
      "children": [
        "/_dashboard/$account/$graph/branches/$branch/proposals/$id/_proposal/diff",
        "/_dashboard/$account/$graph/branches/$branch/proposals/$id/_proposal/editor",
        "/_dashboard/$account/$graph/branches/$branch/proposals/$id/_proposal/overview",
        "/_dashboard/$account/$graph/branches/$branch/proposals/$id/_proposal/"
      ]
    },
    "/_dashboard/$account/$graph/branches/$branch/schema/sdl/federated-schema": {
      "filePath": "_dashboard.$account.$graph.branches.$branch.schema.sdl.federated-schema.tsx",
      "parent": "/_dashboard/$account/$graph/branches/$branch/schema/sdl"
    },
    "/_dashboard/$account/$graph/branches/$branch/settings/branch/danger-zone": {
      "filePath": "_dashboard.$account.$graph.branches.$branch.settings.branch.danger-zone.tsx",
      "parent": "/_dashboard/$account/$graph/branches/$branch"
    },
    "/_dashboard/$account/$graph/branches/$branch/settings/branch/pathfinder": {
      "filePath": "_dashboard.$account.$graph.branches.$branch.settings.branch.pathfinder.tsx",
      "parent": "/_dashboard/$account/$graph/branches/$branch"
    },
    "/_dashboard/$account/$graph/branches/$branch/settings/branch/proposals": {
      "filePath": "_dashboard.$account.$graph.branches.$branch.settings.branch.proposals.tsx",
      "parent": "/_dashboard/$account/$graph/branches/$branch"
    },
    "/_dashboard/$account/$graph/branches/$branch/settings/graph/branches": {
      "filePath": "_dashboard.$account.$graph.branches.$branch.settings.graph.branches.tsx",
      "parent": "/_dashboard/$account/$graph/branches/$branch"
    },
    "/_dashboard/$account/$graph/branches/$branch/settings/graph/checks": {
      "filePath": "_dashboard.$account.$graph.branches.$branch.settings.graph.checks.tsx",
      "parent": "/_dashboard/$account/$graph/branches/$branch",
      "children": [
        "/_dashboard/$account/$graph/branches/$branch/settings/graph/checks/"
      ]
    },
    "/_dashboard/$account/$graph/branches/$branch/settings/graph/danger-zone": {
      "filePath": "_dashboard.$account.$graph.branches.$branch.settings.graph.danger-zone.tsx",
      "parent": "/_dashboard/$account/$graph/branches/$branch"
    },
    "/_dashboard/$account/$graph/branches/$branch/schema/sdl/": {
      "filePath": "_dashboard.$account.$graph.branches.$branch.schema.sdl.index.tsx",
      "parent": "/_dashboard/$account/$graph/branches/$branch/schema/sdl"
    },
    "/_dashboard/$account/$graph/branches/$branch/settings/graph/": {
      "filePath": "_dashboard.$account.$graph.branches.$branch.settings.graph.index.tsx",
      "parent": "/_dashboard/$account/$graph/branches/$branch"
    },
    "/_dashboard/$account/$graph/branches/$branch/proposals/$id/_proposal/diff": {
      "filePath": "_dashboard.$account.$graph.branches.$branch.proposals.$id._proposal.diff.tsx",
      "parent": "/_dashboard/$account/$graph/branches/$branch/proposals/$id/_proposal"
    },
    "/_dashboard/$account/$graph/branches/$branch/proposals/$id/_proposal/editor": {
      "filePath": "_dashboard.$account.$graph.branches.$branch.proposals.$id._proposal.editor.tsx",
      "parent": "/_dashboard/$account/$graph/branches/$branch/proposals/$id/_proposal"
    },
    "/_dashboard/$account/$graph/branches/$branch/proposals/$id/_proposal/overview": {
      "filePath": "_dashboard.$account.$graph.branches.$branch.proposals.$id._proposal.overview.tsx",
      "parent": "/_dashboard/$account/$graph/branches/$branch/proposals/$id/_proposal"
    },
    "/_dashboard/$account/$graph/branches/$branch/schema/sdl/subgraph/$subgraph": {
      "filePath": "_dashboard.$account.$graph.branches.$branch.schema.sdl.subgraph.$subgraph.tsx",
      "parent": "/_dashboard/$account/$graph/branches/$branch/schema/sdl"
    },
    "/_dashboard/$account/$graph/branches/$branch/proposals/$id/_proposal/": {
      "filePath": "_dashboard.$account.$graph.branches.$branch.proposals.$id._proposal.index.tsx",
      "parent": "/_dashboard/$account/$graph/branches/$branch/proposals/$id/_proposal"
    },
    "/_dashboard/$account/$graph/branches/$branch/settings/graph/checks/": {
      "filePath": "_dashboard.$account.$graph.branches.$branch.settings.graph.checks.index.tsx",
      "parent": "/_dashboard/$account/$graph/branches/$branch/settings/graph/checks"
    }
  }
}
ROUTE_MANIFEST_END */
