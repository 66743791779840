import { StrictMode } from 'react'

import { ThemeProvider } from '#lib/theme/theme-context'
import { colorTheme } from '#lib/theme/theme.css'
import { Toaster } from '#lib/toast/toast'
import { RouterProvider } from '@tanstack/react-router'
// Polyfill Promise.withResolvers() until support has increased https://caniuse.com/mdn-javascript_builtins_promise_withresolvers
import '@ungap/with-resolvers'
import ReactDOM from 'react-dom/client'

import { Config } from './config'
import './global-theme.css'
import './global.css'
import { router } from './router'
import {
  ThirdPartyTools,
  setupThirdPartyTools,
} from './third-party/third-party-tools'

if (Config.IS_MANAGED_BUILD) {
  setupThirdPartyTools()
}

if (typeof document !== 'undefined') {
  document.body.classList.add(colorTheme)
}

const rootElement = document.getElementById('root')

if (rootElement && !rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement)
  root.render(<App />)
}

function App() {
  return (
    <StrictMode>
      <ThemeProvider>
        <RouterProvider router={router} />

        <Toaster />
      </ThemeProvider>

      {Config.IS_MANAGED_BUILD && <ThirdPartyTools />}
    </StrictMode>
  )
}
