/**
 * @generated SignedSource<<e0eb30cb37f51e982948f76e05171094>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type deploymentsPageQuery$variables = {
  account: string;
  before?: string | null | undefined;
  branch: string;
  graph: string;
  last?: number | null | undefined;
};
export type deploymentsPageQuery$data = {
  readonly graph: {
    readonly " $fragmentSpreads": FragmentRefs<"deploymentsPageListFragment">;
  } | null | undefined;
};
export type deploymentsPageQuery = {
  response: deploymentsPageQuery$data;
  variables: deploymentsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "account"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "branch"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "graph"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v5 = [
  {
    "kind": "Variable",
    "name": "accountSlug",
    "variableName": "account"
  },
  {
    "kind": "Variable",
    "name": "graphSlug",
    "variableName": "graph"
  }
],
v6 = {
  "kind": "Variable",
  "name": "before",
  "variableName": "before"
},
v7 = {
  "kind": "Variable",
  "name": "branch",
  "variableName": "branch"
},
v8 = {
  "kind": "Variable",
  "name": "last",
  "variableName": "last"
},
v9 = [
  (v6/*: any*/),
  {
    "fields": [
      (v7/*: any*/)
    ],
    "kind": "ObjectValue",
    "name": "filter"
  },
  (v8/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "deploymentsPageQuery",
    "selections": [
      {
        "alias": "graph",
        "args": (v5/*: any*/),
        "concreteType": "Graph",
        "kind": "LinkedField",
        "name": "graphByAccountSlug",
        "plural": false,
        "selections": [
          {
            "args": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "deploymentsPageListFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "deploymentsPageQuery",
    "selections": [
      {
        "alias": "graph",
        "args": (v5/*: any*/),
        "concreteType": "Graph",
        "kind": "LinkedField",
        "name": "graphByAccountSlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v9/*: any*/),
            "concreteType": "DeploymentConnection",
            "kind": "LinkedField",
            "name": "deployments",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasPreviousPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startCursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "DeploymentEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Deployment",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isRedeployable",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "triggerType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v9/*: any*/),
            "filters": [
              "filter"
            ],
            "handle": "connection",
            "key": "deploymentsPageListFragment_deployments",
            "kind": "LinkedHandle",
            "name": "deployments"
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e97893b7ba8e9c4f7e522a6e27944e75",
    "id": null,
    "metadata": {},
    "name": "deploymentsPageQuery",
    "operationKind": "query",
    "text": "query deploymentsPageQuery(\n  $account: String!\n  $graph: String!\n  $branch: String!\n  $last: Int\n  $before: String\n) {\n  graph: graphByAccountSlug(accountSlug: $account, graphSlug: $graph) {\n    ...deploymentsPageListFragment_1aAbgz\n    id\n  }\n}\n\nfragment commitDetailsDeploymentFragment on Deployment {\n  triggerType\n}\n\nfragment deploymentListItemFullFragment on Deployment {\n  id\n  createdAt\n  isRedeployable\n  status\n  ...commitDetailsDeploymentFragment\n}\n\nfragment deploymentsPageListFragment_1aAbgz on Graph {\n  deployments(filter: {branch: $branch}, last: $last, before: $before) {\n    pageInfo {\n      endCursor\n      hasNextPage\n      hasPreviousPage\n      startCursor\n    }\n    edges {\n      node {\n        id\n        ...deploymentListItemFullFragment\n        __typename\n      }\n      cursor\n    }\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "d02f19541034c7c81ec98ca015f5fbd5";

export default node;
