/**
 * @generated SignedSource<<4a0253b6f3abd4f0ae92a67d2c040b59>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type accountAccessTokensPageQuery$variables = {
  account: string;
};
export type accountAccessTokensPageQuery$data = {
  readonly account: {
    readonly " $fragmentSpreads": FragmentRefs<"accessTokensPageAccountFragment">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"accessTokensPageFragment">;
};
export type accountAccessTokensPageQuery = {
  response: accountAccessTokensPageQuery$data;
  variables: accountAccessTokensPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "account"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "account"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v6 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "accountAccessTokensPageQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "accessTokensPageFragment"
      },
      {
        "alias": "account",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "accountBySlug",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "accessTokensPageAccountFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "accountAccessTokensPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationConnection",
            "kind": "LinkedField",
            "name": "organizations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "account",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "accountBySlug",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isAccount"
          },
          (v2/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "AccessTokenConnection",
            "kind": "LinkedField",
            "name": "accessTokens",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AccessTokenEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AccessToken",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "accountId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Graph",
                        "kind": "LinkedField",
                        "name": "graphScopes",
                        "plural": true,
                        "selections": [
                          (v5/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "kind": "ClientExtension",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__id",
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": "accessTokens(first:1000)"
          },
          {
            "alias": null,
            "args": (v6/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "accessTokensPageAccountFragment_accessTokens",
            "kind": "LinkedHandle",
            "name": "accessTokens"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "GraphConnection",
            "kind": "LinkedField",
            "name": "graphs",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Graph",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5fa30b26f2eecd483d3106189282599e",
    "id": null,
    "metadata": {},
    "name": "accountAccessTokensPageQuery",
    "operationKind": "query",
    "text": "query accountAccessTokensPageQuery(\n  $account: String!\n) {\n  ...accessTokensPageFragment\n  account: accountBySlug(slug: $account) {\n    __typename\n    ...accessTokensPageAccountFragment\n    id\n  }\n}\n\nfragment accessTokensPageAccountFragment on Account {\n  __isAccount: __typename\n  id\n  slug\n  accessTokens(first: 1000) {\n    edges {\n      node {\n        id\n        createdAt\n        ...tokenRowDetails\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  graphs {\n    edges {\n      node {\n        id\n        slug\n      }\n    }\n  }\n}\n\nfragment accessTokensPageFragment on Query {\n  viewer {\n    organizations {\n      nodes {\n        id\n        name\n      }\n    }\n    id\n  }\n}\n\nfragment tokenRowDetails on AccessToken {\n  id\n  name\n  accountId\n  createdAt\n  graphScopes {\n    slug\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "65a7dda0523c6dece874d335f0fff1e0";

export default node;
