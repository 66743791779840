import node, {
  type accountLayoutQuery,
} from '#gen/relay/accountLayoutQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_dashboard/$account')({
  staticData: { title: '' },
  loader({ context }) {
    return context.preload<accountLayoutQuery>(node)
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/account/account-layout'),
    'AccountLayout',
  ),
})
