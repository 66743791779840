import node, {
  type federatedSchemaLayoutQuery,
} from '#gen/relay/federatedSchemaLayoutQuery.graphql'
import { ErrorHandler } from '#lib/errors/errors'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/schema/sdl',
)({
  staticData: { title: '' },
  errorComponent: ErrorHandler,
  loader({ context, params }) {
    return context.preload<federatedSchemaLayoutQuery>(node, params)
  },
  component: lazyLoadedComponent(
    () => import('../pages/branch/schema/federated-schema-layout'),
    'FederatedSchemaLayout',
  ),
})
