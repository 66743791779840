import node, {
  type dashboardLayoutQuery,
} from '#gen/relay/dashboardLayoutQuery.graphql'
import { authClient } from '#lib/auth/auth-client'
import { redirectToAuth } from '#lib/auth/redirect-to-auth'
import { createRelayEnvironment } from '#lib/relay/relay-environment'
import {
  type RelayPreloader,
  createRelayPreloader,
} from '#lib/relay/relay-preloader'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute } from '@tanstack/react-router'
import type { Environment } from 'relay-runtime'

let relayEnvironment: Environment | undefined
let preload: RelayPreloader | undefined

export const Route = createFileRoute('/_dashboard')({
  staticData: { title: '' },
  async beforeLoad({ location }) {
    function getToken() {
      return authClient.getToken()
    }

    const isSignedIn = await authClient.isSignedIn()

    if (!isSignedIn) {
      relayEnvironment = undefined
      await redirectToAuth(location.href)
    }

    relayEnvironment ??= createRelayEnvironment(getToken)
    preload ??= createRelayPreloader(relayEnvironment)

    return { relayEnvironment, preload }
  },
  async loader({ context }) {
    return context.preload<dashboardLayoutQuery>(node)
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/dashboard/dashboard-layout'),
    'DashboardLayout',
  ),
})
