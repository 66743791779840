/**
 * @generated SignedSource<<fba338ae25033e0a9785d080d836887f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SchemaProposalStatus = "APPROVED" | "DRAFT" | "IMPLEMENTED" | "IN_REVIEW" | "REJECTED";
export type SchemaProposalFilter = {
  status?: SchemaProposalStatus | null | undefined;
};
export type proposalsPageQuery$variables = {
  accountSlug: string;
  after?: string | null | undefined;
  branchName: string;
  filter: SchemaProposalFilter;
  first?: number | null | undefined;
  graphSlug: string;
};
export type proposalsPageQuery$data = {
  readonly branch: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"proposalsListFragment">;
  };
};
export type proposalsPageQuery = {
  response: proposalsPageQuery$data;
  variables: proposalsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountSlug"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "branchName"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "graphSlug"
},
v6 = [
  {
    "kind": "Variable",
    "name": "accountSlug",
    "variableName": "accountSlug"
  },
  {
    "kind": "Variable",
    "name": "graphSlug",
    "variableName": "graphSlug"
  },
  {
    "kind": "Variable",
    "name": "name",
    "variableName": "branchName"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "proposalsPageQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v6/*: any*/),
          "concreteType": "Branch",
          "kind": "LinkedField",
          "name": "branch",
          "plural": false,
          "selections": [
            (v7/*: any*/),
            {
              "args": (v8/*: any*/),
              "kind": "FragmentSpread",
              "name": "proposalsListFragment"
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "branch"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v5/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "proposalsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "Branch",
        "kind": "LinkedField",
        "name": "branch",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "SchemaProposalConnection",
            "kind": "LinkedField",
            "name": "schemaProposals",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SchemaProposalEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SchemaProposal",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "author",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v8/*: any*/),
            "filters": [
              "filter"
            ],
            "handle": "connection",
            "key": "ProposalsList_branch_schemaProposals",
            "kind": "LinkedHandle",
            "name": "schemaProposals"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "49ca96f94b92bbf6fc2c7782a219a77f",
    "id": null,
    "metadata": {},
    "name": "proposalsPageQuery",
    "operationKind": "query",
    "text": "query proposalsPageQuery(\n  $accountSlug: String!\n  $graphSlug: String!\n  $branchName: String!\n  $first: Int\n  $after: String\n  $filter: SchemaProposalFilter!\n) {\n  branch(accountSlug: $accountSlug, graphSlug: $graphSlug, name: $branchName) {\n    id\n    ...proposalsListFragment_G9cLv\n  }\n}\n\nfragment proposalsListFragment_G9cLv on Branch {\n  schemaProposals(first: $first, after: $after, filter: $filter) {\n    edges {\n      node {\n        ...proposalsListItemFragment\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n\nfragment proposalsListItemFragment on SchemaProposal {\n  id\n  title\n  author {\n    name\n    id\n  }\n  status\n  createdAt\n}\n"
  }
};
})();

(node as any).hash = "ee5c8d59130bc4d2760a7ded8d07a9eb";

export default node;
